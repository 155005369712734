import React, { useCallback } from "react";
import { generatePath, useNavigate } from "react-router-dom";

// components
import Button from "../../../../../components/button/Button";

// hooks
import { getBase64ImgSrc } from "../../../../../helpers/getBase64ImageSrc";

// images
import defaultBookCover from "../../../../../assets/images/default_book_cover.png";

// types
import type { CollectionType } from "../../../../../context/collections-provider/CollectionsProvider.types";

// consts
import { PATHS } from "../../../../../route/route.controls";

// styles
import styles from "./Collection.module.scss";

type CollectionProps = {
  collection: CollectionType;
};

export function Collection({ collection }: CollectionProps) {
  const navigate = useNavigate();

  const handleOpenCurrentCollection = useCallback(
    (collectionId: string) => {
      const generatedLink = generatePath(PATHS.collection, {
        collectionId,
      });

      navigate(generatedLink);
    },
    [navigate, PATHS]
  );

  return (
    <div
      className={styles.bookContainer}
      onClick={() => handleOpenCurrentCollection(collection.id)}
    >
      <div className={styles.imagesWrapper}>
        {collection.books.map((book) => (
          <div className={styles.imageWrapper} key={book.id}>
            <img
              src={
                book.pictures[0]
                  ? getBase64ImgSrc(book.pictures[0])
                  : defaultBookCover
              }
              alt="course"
            />
          </div>
        ))}
      </div>

      <div className={styles.info}>
        <p className={styles.description}>{collection.title}</p>
      </div>
      <div className={styles.buttonsWrapper}>
        <Button width="auto">Buy</Button>
        <div>
          <div className={styles.pricesWrapper}>
            {collection.price ? <p>€{collection.price}</p> : null}
          </div>
        </div>
      </div>
    </div>
  );
}
