import React, { useContext, useEffect } from "react";

// components
import Modal from "../modal/Modal";
import Button from "../button/Button";

// images
import defaultBookCover from "../../assets/images/default_book_cover.png";

// context
import { cartContext } from "../../context/cart-provider/CartProvider";

// helpers
import { getBase64ImgSrc } from "../../helpers/getBase64ImageSrc";

// styles
import styles from "./PreviewCartModal.module.scss";

type PreviewCartModalProps = {
  isOpen: boolean;
  onClose: () => void;
  handleOpenCart: () => void;
};

export function PreviewCartModal({
  isOpen,
  onClose,
  handleOpenCart,
}: PreviewCartModalProps) {
  const { cartProducts, totalPrice, getCartProducts } = useContext(cartContext);

  useEffect(() => {
    getCartProducts();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      className={styles.modal}
      variant="cart"
      width="340px"
    >
      <div className={styles.container}>
        <div>
          {cartProducts.length ? (
            <div>
              {cartProducts.map((cartProduct) => (
                <div className={styles.book} key={cartProduct.id}>
                  <div className={styles.bookInfo}>
                    {"pictures" in cartProduct ? (
                      <>
                        <img
                          src={
                            cartProduct.pictures[0]
                              ? getBase64ImgSrc(cartProduct.pictures[0])
                              : defaultBookCover
                          }
                          alt={`${cartProduct.title}`}
                        />
                        <p>{cartProduct.title}</p>
                      </>
                    ) : null}

                    {"books" in cartProduct ? (
                      <>
                        <div className={styles.collectionImageWrapper}>
                          <img
                            src={
                              cartProduct.books[0].pictures[0]
                                ? getBase64ImgSrc(
                                    cartProduct.books[0].pictures[0]
                                  )
                                : defaultBookCover
                            }
                            alt={`${cartProduct.title}`}
                          />

                          {cartProduct.books.length > 1 ? (
                            <div className={styles.leftBooks}>
                              + {cartProduct.books.length - 1}
                            </div>
                          ) : null}
                        </div>

                        <p>{cartProduct.title}</p>
                      </>
                    ) : null}
                  </div>

                  <div className={styles.pricesWrapper}>
                    {"discountPrice" in cartProduct ? (
                      <>
                        {cartProduct.discountPrice ? (
                          <p>€{cartProduct.discountPrice}</p>
                        ) : null}
                        <div
                          className={
                            cartProduct.discountPrice ? styles.diagonalLine : ""
                          }
                        >
                          <p className={styles.oldPrice}>
                            €{cartProduct.price}
                          </p>
                        </div>
                      </>
                    ) : null}

                    {"books" in cartProduct ? (
                      <p>€{cartProduct.price}</p>
                    ) : null}
                  </div>
                </div>
              ))}
              <div className={styles.totalWrapper}>
                <p>Total</p>
                <p>{totalPrice}€</p>
              </div>
            </div>
          ) : (
            <h6 className={styles.emptyCartText}>
              Your shopping cart is empty
            </h6>
          )}
        </div>
        <div className={styles.buttonWrapper}>
          <Button width="auto" onClick={handleOpenCart}>
            View cart
          </Button>
        </div>
      </div>
    </Modal>
  );
}
