import React from "react";
import { useNavigate } from "react-router-dom";

// icons
import { SuccessIcon } from "../../../assets/icons/SuccessIcon";

// consts
import { PATHS } from "../../../route/route.controls";

// components
import Button from "../../../components/button/Button";

// styles
import styles from "./PaymentSuccess.module.scss";

export function PaymentSuccess() {
  const navigate = useNavigate();

  return (
    <section className={styles.section}>
      <div className={styles.mainContent}>
        <SuccessIcon />
        <p className={styles.title}>Payment successfully completed</p>
        <p className={styles.description}>
          You can find your purchase in your profile.
        </p>
      </div>

      <div className={styles.buttonsWrapper}>
        <Button onClick={() => navigate(PATHS.profile)}>My profile</Button>

        <Button
          className={styles.mainButton}
          onClick={() => navigate(PATHS.index)}
        >
          To main page
        </Button>
      </div>
    </section>
  );
}
