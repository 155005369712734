import React from "react";

// icons
import { DeleteIcon } from "../../../assets/icons/DeleteIcon";

// hooks
import { useProfileAdminFetch, useProfileForm } from "./use-profile-user";

// helpers
import { formattedDate } from "../../../helpers/format-date";

// components
import Input from "../../../components/input/Input";
import Button from "../../../components/button/Button";
import Spinner from "../../../components/spinner/Spinner";

// styles
import styles from "./ProfileUserScreen.module.scss";

export function ProfileAdminScreen() {
  const { isProfileLoading } = useProfileAdminFetch();
  const {
    profileFormData,
    fileInputRef,
    isDisabledSubmitButton,
    validatePassword,
    handleChangeProfileData,
    handleRemoveAvatar,
    onSubmit,
  } = useProfileForm();

  return (
    <form className={styles.formWrapper}>
      {isProfileLoading ? (
        <Spinner />
      ) : (
        <>
          <div className={styles.description}>
            <p>
              Last access to site: {formattedDate(profileFormData?.updatedAt)}
            </p>
            <p>
              First access to site: {formattedDate(profileFormData?.createdAt)}
            </p>
          </div>

          <div className={styles.inputWrapper}>
            <Input
              name="firstName"
              label="Name"
              value={profileFormData?.firstName}
              onChange={handleChangeProfileData}
            />
          </div>

          <div className={styles.inputWrapper}>
            <Input
              label="Surname"
              name="lastName"
              value={profileFormData?.lastName}
              onChange={handleChangeProfileData}
            />
          </div>

          <div className={styles.inputWrapper}>
            <Input
              label="Phone number"
              name="phone"
              value={profileFormData?.phone}
              onChange={handleChangeProfileData}
            />
          </div>

          <div className={styles.fileWrapper}>
            <p>Change avatar</p>

            {profileFormData?.avatar ? (
              <div className={styles.pictureWrapper}>
                <img
                  src={`data:image/png;base64, ${profileFormData.avatar}`}
                  alt="profile avatar"
                />
                <DeleteIcon
                  onClick={handleRemoveAvatar}
                  className={styles.deleteIcon}
                />
              </div>
            ) : (
              <div className={styles.buttonWrapper}>
                <input
                  type="file"
                  name="avatar"
                  onChange={handleChangeProfileData}
                  ref={fileInputRef}
                  style={{ display: "none" }}
                />
                <Button
                  side="admin"
                  width="auto"
                  onClick={(e) => {
                    e.preventDefault();
                    fileInputRef.current?.click();
                  }}
                >
                  Choose file
                </Button>
                <p>No file choosen</p>
              </div>
            )}
          </div>

          <div className={styles.inputWrapper}>
            <Input
              name="password"
              type="password"
              label="Current password"
              value={profileFormData?.password}
              onChange={handleChangeProfileData}
              validation={validatePassword("password")}
            />
          </div>

          <div className={styles.inputWrapper}>
            <Input
              type="password"
              name="newPassword"
              label="New password"
              value={profileFormData?.newPassword}
              onChange={handleChangeProfileData}
              validation={validatePassword("newPassword")}
            />
          </div>

          <div className={styles.profileFooter}>
            <Button
              onClick={(e) => onSubmit(e)}
              width="auto"
              disabled={isDisabledSubmitButton}
            >
              Submit
            </Button>
          </div>
        </>
      )}
    </form>
  );
}
