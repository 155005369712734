import React, { SVGProps } from "react";

const DefaultImageIcon = ({
  className,
  color = "#C7C7C7",
  width = 32,
  height = 32,
  ...props
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      className={className}
      viewBox="0 0 32 32"
      fill="none"
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3 21L9.87868 14.1213C11.0503 12.9497 12.9497 12.9497 14.1213 14.1213L21 21M19 19L20.8787 17.1213C22.0503 15.9497 23.9497 15.9497 25.1213 17.1213L29 21M5 26H27C28.1046 26 29 25.1046 29 24V8C29 6.89543 28.1046 6 27 6H5C3.89543 6 3 6.89543 3 8V24C3 25.1046 3.89543 26 5 26ZM19 11H19.01V11.01H19V11ZM19.5 11C19.5 11.2761 19.2761 11.5 19 11.5C18.7239 11.5 18.5 11.2761 18.5 11C18.5 10.7239 18.7239 10.5 19 10.5C19.2761 10.5 19.5 10.7239 19.5 11Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export { DefaultImageIcon };
