// images
import introduction_books from "../../../../assets/images/introduction_books.png";
import books_src from "../../../../assets/images/books.png";
import hygge_book_src from "../../../../assets/images/hygge_book.png";

// icons
import { LogoWithUnderline } from "../../../../assets/icons/LogoWithUnderline";

//translations
import { Trans } from "@lingui/react";

export const introductionItemsData = [
  {
    title: (
      <>
        <h2>
          <Trans id="Fuel your" />
        </h2>
        <h2>
          <Trans id="motivation with" />
        </h2>
        <LogoWithUnderline />
      </>
    ),
    description: (
      <>
        <span>
          <Trans id="Fuel your motivation with our wide selection of books on cultivating self-esteem and setting goals for achieving success." />
          ,
        </span>
      </>
    ),
    img: <img src={introduction_books} alt="books on hand" />,
  },
  {
    title: (
      <>
        <h2>
          <Trans id="Discover worlds" />
        </h2>
        <h2>
          <Trans id="within pages" />
        </h2>
        <h2>
          at <LogoWithUnderline />
        </h2>
      </>
    ),
    description: (
      <>
        <span>
          <Trans id="Dive into captivating stories, enrich your mind, and fuel your imagination with our curated collection. Experience the joy of discovery as you explore boundless realms through the power of books." />
        </span>
      </>
    ),
    img: <img src={books_src} alt="hygge book" />,
  },
  {
    title: (
      <>
        <h2>
          <Trans id="Discover top" />
        </h2>
        <h2>
          <Trans id="rated books of" />
        </h2>
        <h2>
          <Trans id="last month" />
        </h2>
      </>
    ),
    description: (
      <>
        <span>
          <Trans id="Uncover the literary gems that captured readers' hearts with our selection of top-rated books from last month.Explore the titles that garnered rave reviews and discover your next must-read masterpiece." />
        </span>
      </>
    ),
    img: <img src={hygge_book_src} alt="books on hand" />,
  },
];
