import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// context
import { errorContext } from "../../../context/error-provider/ErrorProvider";
import { booksContext } from "../../../context/books-provider/BooksProvider";

// context
export function useBooksFetch() {
  const { error } = useContext(errorContext);
  const { getBooksByGenre, getBooks } = useContext(booksContext);

  const { genre } = useParams();

  const [isBooksLoading, setIsBooksLoading] = useState(true);

  const [itemOffset, setItemOffset] = useState(0);

  const booksAdminFetch = async () => {
    try {
      setIsBooksLoading(true);

      await getBooks(undefined, undefined, true);

      if (genre) {
        const category =
          genre === "time-management" ? "Time management" : genre;

        await getBooksByGenre(category, 30, itemOffset);
      }
    } catch (err) {
      error(err);
    } finally {
      setIsBooksLoading(false);
    }
  };

  const bookTitle = () => {
    switch (genre) {
      case "business":
        return "business";

      case "management":
        return "management";

      case "self-realization":
        return "self realization";

      case "motivation":
        return "motivation";

      case "time-management":
        return "time management";
      default:
        return "";
    }
  };

  useEffect(() => {
    booksAdminFetch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemOffset, genre]);

  return {
    isBooksLoading,
    itemOffset,
    bookTitle,
    setItemOffset,
  };
}
