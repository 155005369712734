import React, { useCallback, useContext } from "react";

// hooks
import { useTeacherForm } from "./use-teacher-form";

// context
import { errorContext } from "../../context/error-provider/ErrorProvider";
import { localesContext } from "../../context/local-provider/LocalProvider";

// components
import Input from "../input/Input";
import Textarea from "../textarea/Textarea";
import Button from "../button/Button";

// icons
import { CloseIcon } from "../../assets/icons/CloseIcon";

// styles
import styles from "./TeacherForm.module.scss";

//translations
import { t } from "@lingui/macro";
import { Trans } from "@lingui/react";

interface TeacherFormProps {
  onClose: () => void;
}

export function TeacherForm({ onClose }: TeacherFormProps) {
  const { i18n } = useContext(localesContext);

  const { error } = useContext(errorContext);

  const {
    teacherFormData,
    isDisabledSubmitButton,
    handleChangeTeacherFormData,
    onSubmit,
  } = useTeacherForm();

  const handleSubmit = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      try {
        await onSubmit(e);
        onClose();
      } catch (e) {
        error(e);
      }
    },
    [onSubmit],
  );

  return (
    <div className={styles.modal}>
      <div className={styles.header}>
        <div className={styles.circles}>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div onClick={onClose} className={styles.closeIcon}>
          <CloseIcon width={22} height={22} />
        </div>
      </div>

      <form className={styles.form}>
        <div className={styles.headerForm}>
          <h4>
            <b>
              <Trans id="Sign up for a group" />
            </b>{" "}
            <Trans id="with a specialised coach in: management, business and soft skills" />
          </h4>
        </div>

        <div className={styles.formBody}>
          <div className={styles.inputWrapper}>
            <div className={styles.inputGroup}>
              <Input
                variant="secondary"
                placeholder={t(i18n)`Your first name*`}
                name="firstName"
                value={teacherFormData.firstName}
                onChange={handleChangeTeacherFormData}
              />
              <Input
                variant="secondary"
                placeholder={t(i18n)`Your last name*`}
                name="lastName"
                value={teacherFormData.lastName}
                onChange={handleChangeTeacherFormData}
              />
            </div>
            <div className={styles.inputGroup}>
              <Input
                variant="secondary"
                placeholder={t(i18n)`Your email*`}
                name="email"
                value={teacherFormData.email}
                onChange={handleChangeTeacherFormData}
              />
            </div>
            <div className={styles.inputGroup}>
              <Input
                variant="secondary"
                placeholder={t(i18n)`Social media`}
                name="socialMedia"
                value={teacherFormData.socialMedia}
                onChange={handleChangeTeacherFormData}
              />
            </div>
          </div>
          <div>
            <Textarea
              id={"feedback_message"}
              placeholder={t(i18n)`How can we help you?`}
              name="message"
              value={teacherFormData.message}
              onChange={handleChangeTeacherFormData}
              rows={2}
            />
          </div>
          <div className={styles.buttonWrapper}>
            <Button disabled={isDisabledSubmitButton} onClick={handleSubmit}>
              <Trans id="Send" />
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
}
