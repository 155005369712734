import React, { useCallback, useContext } from "react";

// components
import Modal from "../../../../components/modal/Modal";
import Input from "../../../../components/input/Input";
import Button from "../../../../components/button/Button";
import Textarea from "../../../../components/textarea/Textarea";
import Checkbox from "../../../../components/checkbox/Checkbox";

// hooks
import { useWindowSize } from "../../../../hooks/use-window-size/use-window-size";
import { useCouponAdminForm } from "../use-coupons-admin";

// context
import { errorContext } from "../../../../context/error-provider/ErrorProvider";
import { localesContext } from "../../../../context/local-provider/LocalProvider";

// types
import type { CouponType } from "../../../../context/coupon-provider/CouponsProvider.types";

// styles
import styles from "./CouponAdminModal.module.scss";

//translations
import { t } from "@lingui/macro";
import { Trans } from "@lingui/react";

type CouponAdminModalProps = {
  coupon: CouponType | null;
  isOpen: "create" | "edit" | null;
  onClose: () => void;
};

export function CouponAdminModal({
  coupon,
  isOpen,
  onClose,
}: CouponAdminModalProps) {
  const { i18n } = useContext(localesContext);

  const { error } = useContext(errorContext);

  const {
    couponFormData,
    isDisabledSubmitButton,
    handleChangeCouponsData,
    onSubmit,
  } = useCouponAdminForm(isOpen, coupon);

  const { width } = useWindowSize();

  const isCreatingModal = isOpen === "create";

  const modalHeader = isCreatingModal
    ? t(i18n)`Create new coupon`
    : t(i18n)`Edit coupon`;

  const handleSubmit = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      try {
        await onSubmit(e);
        onClose();
      } catch (e) {
        error(e);
      }
    },
    [onSubmit, onClose, error],
  );

  return (
    <Modal
      isOpen={Boolean(isOpen)}
      onClose={onClose}
      header={modalHeader}
      width={width > 767 ? "40%" : "97%"}>
      <form className={styles.form}>
        <div className={styles.inputWrapper}>
          <Input
            label={t(i18n)`Code`}
            name="code"
            value={couponFormData.code}
            onChange={handleChangeCouponsData}
          />
          <Input
            label={t(i18n)`Discount`}
            name="discount"
            type="number"
            value={couponFormData.discount}
            onChange={handleChangeCouponsData}
          />
        </div>

        <div className={styles.inputWrapper}>
          <Textarea
            id={t(i18n)`description`}
            label="Description"
            name="description"
            variant="admin"
            value={couponFormData.description}
            onChange={handleChangeCouponsData}
          />
        </div>

        <div className={styles.inputWrapper}>
          <div className={styles.checkboxWrapper}>
            <Checkbox
              id="Is activated"
              name="isActivated"
              value="1"
              checked={couponFormData.isActivated}
              onChange={handleChangeCouponsData}
            />
            <p className={styles.checkboxText}>Is Activated</p>
          </div>
        </div>

        <div className={styles.buttonsWrapper}>
          <Button
            onClick={onClose}
            variant="outline"
            className={styles.cancelButton}>
            <Trans id="Cancel" />
          </Button>

          <Button
            onClick={handleSubmit}
            type="submit"
            disabled={isDisabledSubmitButton}>
            <Trans id="Submit" />
          </Button>
        </div>
      </form>
    </Modal>
  );
}
