// consts
import { PATHS } from "../../route/route.controls";

export const adminLinksData = [
  {
    to: PATHS.profile,
    title: "Profile",
  },
  {
    to: PATHS.history,
    title: "History",
  },
  {
    to: PATHS.adminBooks,
    title: "Admin Panel",
  },
];

export const userLinksData = [
  {
    to: PATHS.profile,
    title: "Profile",
  },
  {
    to: PATHS.history,
    title: "History",
  },
];
