import { z } from "zod";

// schemas
import { bookSchema } from "./../books-provider/BooksProvider.schemas";

export const orderSchema = z.object({
  id: z.string(),
  status: z.enum(["Completed", "Declined", "Error", "Refunded", "Pending"]),
  createdAt: z.string().optional(),
  updatedAt: z.string().optional(),
  books: z.array(bookSchema),
});

export const allOrdersSchema = z.object({
  total: z.number(),
  data: z.array(orderSchema),
});

export const orderStatusSchema = z.object({
  id: z.string(),
  status: z.string(),
});
