import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";

// components
import { Header } from "../../components/header/Header";
import { Footer } from "../../components/footer/Footer";
import { TeacherNotification } from "../../components/notification-teacher/TeacherNotification";
import { TeacherForm } from "../../components/teacher-form/TeacherForm";

// styles
import styles from "./GuestLayout.module.scss";

export function GuestLayout(): JSX.Element {
  const [isOpenTeacherNotification, setIsOpenTeacherNotification] =
    useState(false);
  const [isOpenTeacherForm, setIsOpenTeacherForm] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!isOpenTeacherForm) {
        setIsOpenTeacherNotification(true);
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [isOpenTeacherForm]);

  return (
    <div className={styles.mainSection}>
      <Header />

      <Outlet />

      <Footer />

      {isOpenTeacherNotification && (
        <TeacherNotification
          onClick={() => setIsOpenTeacherForm(true)}
          onClose={() => setIsOpenTeacherNotification(false)}
        />
      )}

      {isOpenTeacherForm ? (
        <TeacherForm onClose={() => setIsOpenTeacherForm(false)} />
      ) : null}
    </div>
  );
}
