import React from "react";

// images
import visa_image from "../../assets/images/visa_image.png";

// icons
import { LogoIcon } from "../../assets/icons/LogoIcon";
import { ArrowRightIcon } from "../../assets/icons/ArrowRightIcon";

// consts
import { footerData } from "./Footer.consts";

// components
import Input from "../input/Input";

// styles
import styles from "./Footer.module.scss";

export function Footer() {
  return (
    <footer className={styles.footer}>
      <div className={styles.contentWrapper}>
        <div className={styles.navigationWrapper}>
          <div className={styles.footerInfo}>
            <LogoIcon color="#fff" />
            <div>
              <p>Don't miss our updates!</p>
              <p>Register to follow the latest news</p>
            </div>
            <img
              src={visa_image}
              alt="visa cards"
              className={styles.visaImage}
            />
          </div>
          <div className={styles.footerItemWrapper}>
            {footerData.map((footerItem) => (
              <div key={footerItem.title} className={styles.titleWrapper}>
                <p className={styles.title}>{footerItem.title}</p>

                <div className={styles.footerItems}>
                  {footerItem.items.map((item, index) => (
                    <p className={styles.footerItem} key={index}>
                      {item}
                    </p>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.footerTitle}>
          <p>Thrivestore.co.uk</p>
        </div>
      </div>
    </footer>
  );
}
