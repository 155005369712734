import React, { useContext } from "react";

// components
import { RatedBooks } from "../../guest/main-screen/rated-books/RatedBooks";
import { Pagination } from "../../../components/pagination/Pagination";
import Spinner from "../../../components/spinner/Spinner";

// context
import { collectionsContext } from "../../../context/collections-provider/CollectionsProvider";
import { localesContext } from "../../../context/local-provider/LocalProvider";

// hooks
import { useCollectionsFetch } from "./use-collections";

// styles
import styles from "./CollectionsScreen.module.scss";

//translations
import { Trans } from "@lingui/react";
import { t } from "@lingui/macro";

export function CollectionsScreen() {
  const { i18n } = useContext(localesContext);
  const { total, collectionsData } = useContext(collectionsContext);

  const { isCollectionsLoading, setItemOffset } = useCollectionsFetch();

  return (
    <div className={styles.container}>
      <div className={styles.collectionsWrapper}>
        <div className={styles.orangeCircle} />

        <div className={styles.redCircle} />

        <div className={styles.redDownCircle} />

        <div className={styles.yellowCircle} />
        {isCollectionsLoading ? (
          <div className={styles.spinner}>
            <Spinner color="dark" />
          </div>
        ) : (
          <>
            {collectionsData && collectionsData.length ? (
              <>
                <RatedBooks
                  title={t(i18n)`Best collections`}
                  type="collections"
                  collections={collectionsData}
                />
                <Pagination
                  amountOfItems={30}
                  length={total}
                  setItemOffset={setItemOffset}
                />
              </>
            ) : (
              <h4 className={styles.title}>
                <Trans id="Regrettably, there are currently no books on collections." />
              </h4>
            )}
          </>
        )}
      </div>
    </div>
  );
}
