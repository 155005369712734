// consts
import { PATHS } from "../../route/route.controls";

export const navigationLinks = [
  {
    title: "Business",
    to: `${PATHS.books}/genre/business`,
  },
  {
    title: "Management",
    to: `${PATHS.books}/genre/management`,
  },
  {
    title: "Self realization",
    to: `${PATHS.books}/genre/self-realization`,
  },
  {
    title: "Motivation",
    to: `${PATHS.books}/genre/motivation`,
  },
  {
    title: "Time management",
    to: `${PATHS.books}/genre/time-management`,
  },
  {
    title: "Collections",
    to: PATHS.collections,
  },
];

export const adminLinksData = [
  {
    to: PATHS.profile,
    title: "Profile",
  },
  {
    to: PATHS.history,
    title: "History",
  },
  {
    to: PATHS.adminBooks,
    title: "Admin Panel",
  },
];

export const userLinksData = [
  {
    to: PATHS.profile,
    title: "Profile",
  },
  {
    to: PATHS.history,
    title: "History",
  },
];
