import React from "react";
import classnames from "classnames";

// components
import Button from "../../../../components/button/Button";
import Input from "../../../../components/input/Input";
import Textarea from "../../../../components/textarea/Textarea";

// icons
import { ArrowRightIcon } from "../../../../assets/icons/ArrowRightIcon";

// hooks
import { useFeedbackModalForm } from "./use-feedback-modal-form";

// styles
import styles from "./Feedback.module.scss";

export function Feedback() {
  const {
    feedbackFormData,
    isDisabledSubmitButton,
    handleChangeFeedbackData,
    onSubmit,
  } = useFeedbackModalForm();

  return (
    <section className={styles.container}>
      <div className={styles.contentWrapper}>
        <h3>Send us a message</h3>
        <form className={styles.form}>
          <div className={styles.formHeader}>
            <div className={classnames(styles.icon, styles.closeIcon)} />
            <div className={classnames(styles.icon, styles.turnIcon)} />
            <div className={classnames(styles.icon, styles.expandIcon)} />
          </div>
          <div className={styles.formBody}>
            <div className={styles.inputWrapper}>
              <div className={styles.inputGroup}>
                <Input
                  variant="secondary"
                  placeholder="Your first name"
                  name="firstName"
                  value={feedbackFormData.firstName}
                  onChange={handleChangeFeedbackData}
                />
                <Input
                  variant="secondary"
                  placeholder="Your last name"
                  name="lastName"
                  value={feedbackFormData.lastName}
                  onChange={handleChangeFeedbackData}
                />
              </div>
              <div className={styles.inputGroup}>
                <Input
                  variant="secondary"
                  placeholder="Your email"
                  name="email"
                  value={feedbackFormData.email}
                  onChange={handleChangeFeedbackData}
                />
                <Input
                  variant="secondary"
                  placeholder="Your phone"
                  name="phone"
                  value={feedbackFormData.phone}
                  onChange={handleChangeFeedbackData}
                />
              </div>
            </div>
            <div>
              <Textarea
                id={"feedback_message"}
                placeholder="How can we help you?"
                name="message"
                value={feedbackFormData.message}
                onChange={handleChangeFeedbackData}
              />
            </div>
            <div className={styles.buttonWrapper}>
              <Button
                width="auto"
                disabled={isDisabledSubmitButton}
                onClick={onSubmit}
                icon={<ArrowRightIcon color="#FFF" />}
              >
                Send
              </Button>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
}
