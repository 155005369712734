import { useContext, useEffect, useState } from "react";

// context
import { errorContext } from "../../../context/error-provider/ErrorProvider";
import { booksContext } from "../../../context/books-provider/BooksProvider";
import { collectionsContext } from "../../../context/collections-provider/CollectionsProvider";

// context
export function useMainScreenFetch() {
  const { error } = useContext(errorContext);
  const { getBooks } = useContext(booksContext);
  const { getCollections } = useContext(collectionsContext);

  const [isMainScreenLoading, setIsMainScreenLoading] = useState(false);

  const booksAndCollectionsFetch = async () => {
    try {
      setIsMainScreenLoading(true);

      await getBooks(undefined, undefined, true);
      await getCollections(undefined, undefined, true);
    } catch (err) {
      error(err);
    } finally {
      setIsMainScreenLoading(false);
    }
  };

  useEffect(() => {
    booksAndCollectionsFetch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isMainScreenLoading,
  };
}
