import { useCallback, useContext } from "react";

// hooks
import { useHistoryUserFetch } from "./use-history-admin";

// contexts
import { orderContext } from "../../../context/order-provider/OrderProvider";
import { localesContext } from "../../../context/local-provider/LocalProvider";
import { storageContext } from "../../../context/storage-provider/StorageProvider";
import { errorContext } from "../../../context/error-provider/ErrorProvider";

// components
import Spinner from "../../../components/spinner/Spinner";
import Button from "../../../components/button/Button";

// helpers
import { formattedDate } from "../../../helpers/format-date";
import { getImageUrl } from "../../../helpers/getImageUrl";
import { autoDownload } from "../../../helpers/autoDownload";

// styles
import styles from "./HistoryUserScreen.module.scss";
import { DownloadIcon } from "../../../assets/icons/DownloadIcon";

//translations
import { Trans } from "@lingui/react";
import { t } from "@lingui/macro";

export function HistoryAdminScreen() {
  const { i18n } = useContext(localesContext);
  const { ordersData } = useContext(orderContext);
  const { error, success } = useContext(errorContext);
  const { downloadPDF } = useContext(storageContext);

  const { isHistoryLoading } = useHistoryUserFetch();

  const handleDownload = useCallback(
    async (key: string | null | undefined, title: string) => {
      if (!key) {
        error(t(i18n)`Invalid file key. Unable to download.`);
        return;
      }

      try {
        const file = await downloadPDF(key);

        if (file) {
          autoDownload(file, title, "application/pdf");
        }

        success(t(i18n)`Book was successfully downloaded`);
      } catch (e) {
        error(e);
      }
    },
    [downloadPDF]
  );

  return (
    <section className={styles.container}>
      {isHistoryLoading ? (
        <Spinner />
      ) : ordersData && ordersData.length ? (
        <div className={styles.tableWrapper}>
          <div className={styles.tableRowHead}>
            <div>
              <Trans id="Photo" />
            </div>
            <div>
              <Trans id="Title" />
            </div>
            <div>
              <Trans id="Description" />
            </div>
            <div>
              <Trans id="Type" />
            </div>
            <div>
              <Trans id="Tags" />
            </div>
            <div>
              <Trans id="Price" />
            </div>
            <div>
              <Trans id="Discount Price" />
            </div>
            <div>
              <Trans id="Status" />
            </div>
            <div>
              <Trans id="Date" />
            </div>
            <div></div>
          </div>

          {ordersData.map((order) => (
            <div className={styles.tableRow} key={order.id}>
              {order.books.map((book) => (
                <div className={styles.tableRowBody} key={book.id}>
                  <div className={styles.imageWrapper}>
                    <img src={getImageUrl(book.picture)} alt="course" />
                  </div>
                  <div>{book.title}</div>
                  <div>{book.description}</div>
                  <div>{book.category}</div>
                  <div>{book.tags}</div>
                  <div>{book.price}</div>
                  <div>{book.discountPrice}</div>
                  <div>{book.isPublished ? t(i18n)`Yes` : t(i18n)`No`}</div>
                  <div>{formattedDate(order.updatedAt)}</div>
                  <DownloadIcon
                    onClick={() => handleDownload(book.source?.key, book.title)}
                    className={styles.downloadIcon}
                  />
                </div>
              ))}
            </div>
          ))}
        </div>
      ) : (
        <h4 className={styles.title}>
          <Trans id="Regrettably, there are currently no history." />
        </h4>
      )}
    </section>
  );
}
