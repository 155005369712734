import React, { useCallback, useContext, useState } from "react";

// hooks
import { useCartModalOrder } from "./use-cart-modal-order";

// components
import Button from "../../button/Button";
import Checkbox from "../../checkbox/Checkbox";
import Input from "../../input/Input";
import { Link } from "react-router-dom";

// consts
import { PATHS } from "../../../route/route.controls";

// context
import { errorContext } from "../../../context/error-provider/ErrorProvider";
import { orderContext } from "../../../context/order-provider/OrderProvider";
import { paymentContext } from "../../../context/payement-provider/PaymentProvider";

// styles
import styles from "./CartModalOrder.module.scss";

type CartModalOrderProps = {
  items: number;
  total: string;
  idsWithQuantity: string[];
  collectionsId: string[];
  onCloseModal: () => void;
};

export function CartModalOrder({
  items,
  total,
  idsWithQuantity,
  collectionsId,
  onCloseModal,
}: CartModalOrderProps) {
  const { error } = useContext(errorContext);
  const { createOrder } = useContext(orderContext);
  const { checkoutPayment } = useContext(paymentContext);

  const {
    couponCode,
    couponDiscountPrice,
    isDisabledSubmitButton,
    handleChangeCouponAdminData,
    onSubmit,
  } = useCartModalOrder();

  const [isConfirmedTerms, setIsConfirmedTerms] = useState(false);

  const handleCheckout = useCallback(async () => {
    try {
      const orderData = await createOrder(
        idsWithQuantity,
        collectionsId,
        couponCode ? couponCode : undefined
      );

      if (orderData) {
        await checkoutPayment(orderData.id);
      }
    } catch (e) {
      error(e);
    }
  }, [checkoutPayment, total, idsWithQuantity, collectionsId, couponCode]);

  return (
    <div className={styles.orderWrapper}>
      <div>
        <h6>Order Summary</h6>
      </div>
      <div className={styles.infoWrapper}>
        <div className={styles.info}>
          <p>Subtotal ({items} items):</p>
          <p>€{total}</p>
        </div>

        <div className={styles.couponWrapper}>
          <Input
            placeholder="Enter coupon"
            value={couponCode}
            onChange={handleChangeCouponAdminData}
          />{" "}
          <Button
            className={styles.couponButton}
            onClick={onSubmit}
            disabled={isDisabledSubmitButton}
          >
            Check coupon
          </Button>
        </div>

        <div className={styles.checkboxWrapper}>
          <Checkbox
            id="terms_and_conditions"
            name="terms"
            value="1"
            checked={isConfirmedTerms}
            onChange={() => setIsConfirmedTerms((prev) => !prev)}
          />

          <p className={styles.checkboxText}>
            I have read and agree with{" "}
            <Link to={PATHS.termsAndConditions} onClick={onCloseModal}>
              Terms of service
            </Link>{" "}
            and{" "}
            <Link to={PATHS.privacyPolicy} onClick={onCloseModal}>
              Privacy policy
            </Link>
          </p>
        </div>

        <div className={styles.total}>
          <p>Total</p>
          {couponDiscountPrice ? (
            <p>
              €{total} - {couponDiscountPrice}% = €
              {(
                Number(total) -
                Number(total) * (couponDiscountPrice / 100)
              ).toFixed(2)}
            </p>
          ) : (
            <p>€{total}</p>
          )}
        </div>
        <div className={styles.separator} />
        <div>
          <Button onClick={handleCheckout} disabled={!isConfirmedTerms}>
            Secure Checkout
          </Button>
        </div>
      </div>
    </div>
  );
}
