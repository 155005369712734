import React, { useCallback, useContext, useState } from "react";

// components
import Spinner from "../../../components/spinner/Spinner";
import { ItemsCounter } from "../../../components/items-counter/ItemsCounter";
import { Pagination } from "../../../components/pagination/Pagination";
import { CollectionAdminModal } from "./CollectionAdminModal/CollectionAdminModal";
import { ConfirmModal } from "../../../components/confirm-modal/ConfirmModal";

// icons
import { EditIcon } from "../../../assets/icons/EditIcon";
import { DeleteIcon } from "../../../assets/icons/DeleteIcon";

// images
import defaultBookCover from "../../../assets/images/default_book_cover.png";

// hooks
import {
  useCollectionAdminForm,
  useCollectionsAdminFetch,
} from "./use-collections-admin";

// helpers
import { getBase64ImgSrc } from "../../../helpers/getBase64ImageSrc";

// context
import { errorContext } from "../../../context/error-provider/ErrorProvider";
import { collectionsContext } from "../../../context/collections-provider/CollectionsProvider";

// types
import type { CollectionType } from "../../../context/collections-provider/CollectionsProvider.types";

// styles
import styles from "./CollectionsAdmin.module.scss";

export function CollectionsAdminScreen() {
  const { error } = useContext(errorContext);
  const { collectionsData } = useContext(collectionsContext);

  const { isCollectionsLoading } = useCollectionsAdminFetch();
  const { handleRemoveCollection } = useCollectionAdminForm();

  const [isOpenCollectionModal, setIsOpenCollectionModal] = useState<
    "create" | "edit" | null
  >(null);
  const [isOpenRemovingCollectionModal, setIsOpenRemovingCollectionModal] =
    useState(false);

  const [selectedCollection, setSelectedCollection] =
    useState<CollectionType | null>(null);

  const [itemsAmount, setItemsAmount] = useState(10);
  const [itemOffset, setItemOffset] = useState(0);

  const itemsPerPage = itemsAmount;
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = collectionsData?.slice(itemOffset, endOffset);

  const handleOpenEditingModal = (collection: CollectionType) => {
    setIsOpenCollectionModal("edit");
    setSelectedCollection(collection);
  };

  const handleOpenDeletingModal = (collection: CollectionType) => {
    setIsOpenRemovingCollectionModal(true);
    setSelectedCollection(collection);
  };

  const onDeleteCollection = useCallback(
    async (collectionId?: string) => {
      try {
        await handleRemoveCollection(collectionId);
      } catch (e) {
        error(e);
      } finally {
        setIsOpenRemovingCollectionModal(false);
      }
    },
    [handleRemoveCollection, setIsOpenRemovingCollectionModal, error]
  );

  if (isCollectionsLoading) {
    return (
      <div className={styles.spinner}>
        <Spinner color="white" />
      </div>
    );
  }

  return (
    <section className={styles.container}>
      <ItemsCounter itemsAmount={itemsAmount} setItemsAmount={setItemsAmount} />

      {collectionsData && currentItems && currentItems.length ? (
        <div className={styles.tableWrapper}>
          <div className={styles.tableRowHead}>
            <div>Books</div>
            <div>Title</div>
            <div>Description</div>
            <div>Tags</div>
            <div>Price</div>
            <div>Activated</div>
            <div></div> {/*For edit button */}
            <div></div> {/*For delete button */}
          </div>

          {currentItems.map((collection) => (
            <div className={styles.tableRowBody} key={collection.id}>
              <div className={styles.imagesWrapper}>
                {collection.books.map((book) => (
                  <div key={book.id} className={styles.imageWrapper}>
                    <img
                      src={
                        book.pictures[0]
                          ? getBase64ImgSrc(book.pictures[0])
                          : defaultBookCover
                      }
                      alt={book.title}
                    />
                  </div>
                ))}
              </div>
              <div>{collection.title}</div>
              <div>{collection.description}</div>
              <div>{collection.tags ? collection.tags.join(", ") : null}</div>
              <div>{collection.price}</div>
              <div>{collection.isPublished ? "Yes" : "No"}</div>
              <div className={styles.icon}>
                <EditIcon
                  color="white"
                  onClick={() => handleOpenEditingModal(collection)}
                />
              </div>
              <div className={styles.icon}>
                <DeleteIcon
                  onClick={() => handleOpenDeletingModal(collection)}
                />
              </div>
            </div>
          ))}

          <Pagination
            variant="admin"
            amountOfItems={itemsPerPage}
            length={collectionsData.length}
            setItemOffset={setItemOffset}
          />
        </div>
      ) : (
        <h4>
          Unfortunately, information about the collections is not available.
        </h4>
      )}

      <CollectionAdminModal
        isOpen={isOpenCollectionModal}
        onClose={() => setIsOpenCollectionModal(null)}
        collection={selectedCollection}
      />

      <ConfirmModal
        isOpen={isOpenRemovingCollectionModal}
        modalTitle="Delete collection"
        modalText="Are you sure you want to delete this collection?"
        onClose={() => setIsOpenRemovingCollectionModal(false)}
        onSubmit={() => onDeleteCollection(selectedCollection?.id)}
      />
    </section>
  );
}
