import { BookAdminFormType } from "../../../context/books-provider/BooksProvider.types";

export const INIT_BOOK_ADMIN_FORM: BookAdminFormType = {
  title: "",
  description: "",
  tags: [],
  category: "",
  author: "",
  price: "",
  discountPrice: null,
  pages: 0,
  pictures: [],
  source: null,
  isPublished: false,
};
