import React, { useCallback, useContext } from "react";

// components
import Button from "../../../../components/button/Button";

// context
import { cartContext } from "../../../../context/cart-provider/CartProvider";

// types
import type { BookType } from "../../../../context/books-provider/BooksProvider.types";
import type { CartProduct } from "../../../../context/cart-provider/CartProvider.types";

// styles
import styles from "./AddToCart.module.scss";

type AddToCartProps = {
  book: BookType;
};

export function AddToCart({ book }: AddToCartProps) {
  const { addBookToCart } = useContext(cartContext);

  const handleAddBookToCart = useCallback(
    (selectedBook: BookType) => {
      const bookWithQuantity = { ...selectedBook, quantity: 1 };

      addBookToCart(bookWithQuantity);
    },
    [addBookToCart]
  );

  return (
    <div className={styles.container}>
      <div className={styles.priceWrapper}>
        <div className={styles.newPriceWrapper}>
          <p>Price</p>
          <p className={styles.newPrice}>
            €{book.discountPrice ? book.discountPrice : book.price}
          </p>
        </div>

        {book.discountPrice ? (
          <div className={styles.oldPriceWrapper}>
            <p>Last price</p>
            <div className={styles.diagonalLine}>
              <p className={styles.oldPrice}>€{book.price}</p>
            </div>
          </div>
        ) : null}
      </div>

      <Button onClick={() => handleAddBookToCart(book)}>Add to cart</Button>
    </div>
  );
}
