import { useCallback, useContext } from "react";
import { generatePath, useNavigate } from "react-router-dom";

// contexts
import { currencyContext } from "../../../../../context/currency-provider/CurrencyProvider";

// components
import Button from "../../../../../components/button/Button";

// hooks
import { getBase64ImgSrc } from "../../../../../helpers/getBase64ImageSrc";

// images
import defaultBookCover from "../../../../../assets/images/default_book_cover.png";

// types
import type { CollectionType } from "../../../../../context/collections-provider/CollectionsProvider.types";

// consts
import { PATHS } from "../../../../../route/route.controls";

// styles
import styles from "./Collection.module.scss";
import { getImageUrl } from "../../../../../helpers/getImageUrl";

//translations
import { Trans } from "@lingui/react";

type CollectionProps = {
  collection: CollectionType;
};

export function Collection({ collection }: CollectionProps) {
  const { getCurrencySymbol } = useContext(currencyContext);

  const navigate = useNavigate();

  const handleOpenCurrentCollection = useCallback(
    (collectionId: string) => {
      const generatedLink = generatePath(PATHS.collection, {
        collectionId,
      });

      navigate(generatedLink);
    },
    [navigate, PATHS],
  );

  return (
    <div
      className={styles.bookContainer}
      onClick={() => handleOpenCurrentCollection(collection.id)}>
      <div className={styles.imagesWrapper}>
        {collection.books.map((book) => (
          <div className={styles.imageWrapper} key={book.id}>
            <img src={getImageUrl(book.picture)} alt="course" />
          </div>
        ))}
      </div>

      <div className={styles.info}>
        <p className={styles.description}>{collection.title}</p>
      </div>
      <div className={styles.buttonsWrapper}>
        <Button width="auto">
          <Trans id="Buy" />
        </Button>
        <div>
          <div className={styles.pricesWrapper}>
            {collection.price ? (
              <p>
                {getCurrencySymbol()}
                {collection.price}
              </p>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
