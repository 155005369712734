// consts
import { PATHS } from "../../route/route.controls";

//context
import { useContext } from "react";
import { localesContext } from "../../context/local-provider/LocalProvider";

//translation
import { t } from "@lingui/macro";

export function useHeaderConsts() {
  const { i18n } = useContext(localesContext);
  const navigationLinks = [
    {
      title: t(i18n)`Business`,
      to: `${PATHS.books}/genre/business`,
    },
    {
      title: t(i18n)`Management`,
      to: `${PATHS.books}/genre/management`,
    },
    {
      title: t(i18n)`Self realization`,
      to: `${PATHS.books}/genre/self-realization`,
    },
    {
      title: t(i18n)`Motivation`,
      to: `${PATHS.books}/genre/motivation`,
    },
    {
      title: t(i18n)`Time management`,
      to: `${PATHS.books}/genre/time-management`,
    },
    {
      title: t(i18n)`Collections`,
      to: PATHS.collections,
    },
  ];

  const adminLinksData = [
    {
      to: PATHS.profile,
      title: t(i18n)`Profile`,
    },
    {
      to: PATHS.history,
      title: t(i18n)`History`,
    },
    {
      to: PATHS.adminBooks,
      title: t(i18n)`Admin Panel`,
    },
  ];

  const userLinksData = [
    {
      to: PATHS.profile,
      title: t(i18n)`Profile`,
    },
    {
      to: PATHS.history,
      title: t(i18n)`History`,
    },
  ];

  return {
    navigationLinks,
    adminLinksData,
    userLinksData,
  };
}
