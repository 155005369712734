import { useContext } from "react";
import { useNavigate } from "react-router-dom";

// contexts
import { cartContext } from "../../context/cart-provider/CartProvider";

// hooks
import { usePreviewModalFetch } from "../preview-cart-modal/use-preview-modal";

// components
import Modal from "../modal/Modal";
import Button from "../button/Button";
import Spinner from "../spinner/Spinner";
import { CartModalBook } from "./cart-modal-book/CartModalBook";
import { CartModalCollection } from "./cart-modal-collection/CartModalCollection";
import { CartModalOrder } from "./cart-modal-order/CartModalOrder";

// consts
import { PATHS } from "../../route/route.controls";

// styles
import styles from "./CartModal.module.scss";

//translation
import { Trans } from "@lingui/react";

type CartModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export function CartModal({ isOpen, onClose }: CartModalProps) {
  const { cartCount } = useContext(cartContext);

  const { isCartLoading, filteredCart, totalPrice } =
    usePreviewModalFetch(isOpen);

  const navigate = useNavigate();

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      header={
        <div>
          <p>
            <Trans id="My order" />
          </p>
          <p className={styles.description}>
            <Trans id="Review and edit your order, then proceed to Checkout." />
          </p>
        </div>
      }
      className={styles.modal}
      width="85%">
      <form className={styles.form}>
        {isCartLoading ? (
          <div>
            <Spinner />
          </div>
        ) : (
          <div>
            {filteredCart ? (
              filteredCart.books.length || filteredCart.collections.length ? (
                <div className={styles.cartWrapper}>
                  <div className={styles.booksWrapper}>
                    {/* BOOKS */}
                    {filteredCart.books.map((book) => (
                      <CartModalBook cartProduct={book} key={book.id} />
                    ))}

                    {/* COLLECTIONS */}
                    {filteredCart.collections.map((collection) => (
                      <CartModalCollection
                        cartProduct={collection}
                        key={collection.id}
                      />
                    ))}
                  </div>

                  <CartModalOrder
                    total={totalPrice}
                    items={cartCount}
                    filteredCart={filteredCart}
                    onCloseModal={onClose}
                  />
                </div>
              ) : (
                <div className={styles.emptyCart}>
                  <h3>
                    <Trans id="Your shopping cart is empty" />
                  </h3>
                  <p>
                    <Trans id="But you can choose something for yourself" />
                  </p>
                  <div>
                    <Button
                      onClick={() => navigate(`${PATHS.books}/genre/business`)}
                      width="auto">
                      <Trans id="Go shopping" />
                    </Button>
                  </div>
                </div>
              )
            ) : null}
          </div>
        )}
      </form>
    </Modal>
  );
}
