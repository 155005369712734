// icons
import { ArrowRightIcon } from "../../../../../assets/icons/ArrowRightIcon";

// components
import Button from "../../../../../components/button/Button";

// types
import type { IntroductionItemType } from "../Introduction.types";

// styles
import styles from "./CarouselItem.module.scss";

//translations
import { Trans } from "@lingui/react";

type CarouselItemProps = {
  introductionItem: IntroductionItemType;
};

export default function CarouselItem({ introductionItem }: CarouselItemProps) {
  return (
    <div className={styles.container}>
      <div className={styles.itemInfo}>
        <div className={styles.title}>{introductionItem.title}</div>
        <p>{introductionItem.description}</p>
        <div>
          <Button width="auto" icon={<ArrowRightIcon color="#fff" />}>
            <Trans id="Explore Now" />
          </Button>
        </div>
      </div>
      <div className={styles.imageWrapper}>{introductionItem.img}</div>
    </div>
  );
}
