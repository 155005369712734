// styles
import styles from "../General.module.scss";

//translation
import { Trans } from "@lingui/react";

export function PrivacyPolicyScreen() {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h4>
          <Trans id="PRIVACY POLICY" />
        </h4>
        <p>09.05.2024</p>
        <p>
          <strong>
            <Trans id="Your access and use of the Services constitutes your agreement to be bound by these Policy. You understand and agree that the Service is provided on an AS IS and AS AVAILABLE basis as amended from time to time." />
          </strong>
        </p>
      </div>
      <p>
        <Trans id="In this Privacy Policy" />{" "}
        <strong>
          <Trans id="Global Stride LTD" />
        </strong>{" "}
        <Trans id="may be referred to as us/our/we. The collection, use, storage and disclosure of your personal information is governed by this Privacy Policy. By using our site, you agree that we may use/disclose/collect personal information in accordance with the current Privacy Policy." />{" "}
        <strong>
          <Trans id="Global Stride LTD" />
        </strong>{" "}
        <Trans id="reserves the right to change our Privacy Policy. All changes will be posted on our website. We recommend that you check our website from time to time for such changes." />
      </p>
      <p>
        <Trans id="Personal information is also considered to be a viewpoint and information used to identify a person." />
      </p>
      <h5>
        <Trans id="What types of personal data we collect" />
      </h5>
      <p>
        <Trans id="Here you can see a list of personal data that our company collects." />{" "}
      </p>
      <ul>
        <li>
          <Trans id="name;" />
        </li>
        <li>
          <Trans id="email address;" />
        </li>
        <li>
          <Trans id="age or date of birth;" />
        </li>
        <li>
          <Trans id="geolocation;" />
        </li>
        <li>
          <Trans id="the page traffic;" />
        </li>
        <li>
          <Trans id="the ID and type of your device;" />
        </li>
        <li>
          <Trans id="credit card information;" />
        </li>
        <li>
          <Trans id="IP address;" />
        </li>
        <li>
          <Trans id="standard data of your web log;" />
        </li>
        <li>
          <Trans id="information about your PC and the network connection;" />
        </li>
        <li>
          <Trans id="phone number;" />
        </li>
        <li>
          <Trans id="Additional information that you provide through our site or indirectly through applications or online services or other sites from which youn authorize us to collect information" />
        </li>
      </ul>
      <p>
        <Trans id="Such personal information may be collected from you or from third parties. We gather this data in case:" />
      </p>
      <ul>
        <li>
          <Trans id="Interactions with our website, advertising, services;" />
        </li>
        <li>
          <Trans id="when communicating via email in case you share information from other social apps/sites" />
        </li>
      </ul>
      <h5>
        <Trans id="Why we use, collect, and disclose personal information" />
      </h5>
      <p>
        <Trans id="Our company may use/store/disclose personal information for the purposes described next:" />
      </p>
      <ul>
        <li>
          <Trans id="to enable you to access and use our website and services;" />
        </li>
        <li>
          <Trans id="to maintain, enhance, secure our website;" />
        </li>
        <li>
          <Trans id="to send service, administrative messages/reminders/technical notifications or security alert messages about the information you requested;" />
        </li>
        <li>
          <Trans id="to comply with legal obligations (resolutions of any complexity that may arise with any of our users) and to enforce consents;" />
        </li>
        <li>
          <Trans id="to send advertising and marketing offers that may potentially be of interest to you (including information that may be sent by our business partners)." />
        </li>
      </ul>
      <p>
        <Trans id="Also, our company reserves the right to transfer your data to a third party. Such data transmitted to a third party may be aggregated to create anonymous consumer information. In turn, the consumer information may be used to track user interests, preferences and to personalize your experience of the services you receive." />
      </p>
      <h5>
        <Trans id="How long We Keep Your Personal Data" />
      </h5>
      <p>
        <Trans id="The company will retain the data for the period necessary to fulfill the purposes specified in this Privacy Policy. We may also retain data for longer if permitted by local laws to which the Company is subject." />
      </p>
      <h5>
        <Trans id="Do we use your personal information for direct marketing" />
      </h5>
      <p>
        <Trans id="The user can receive information/advertisements from us or our business partners. You can get this kind of information by email, sms, Telegram, Facebook messaging, or other ways of contacting you." />
      </p>
      <h5>
        <Trans id="To whom do we disclose your personal information" />
      </h5>
      <p>
        <Trans id="We may disclose personal information to persons identified in this Policy:" />
      </p>
      <ul>
        <li>
          <Trans id="our employees or their related corporate bodies;" />
        </li>
        <li>
          <Trans id="to service providers (including the providers that operate our websites or our business and connect with our services to you), third-party providers, editors;" />
        </li>
        <li>
          <Trans id="professional advisors and agents;" />
        </li>
        <li>
          <Trans id="payment systems operators;" />
        </li>
        <li>
          <Trans id="our current/potential business partner." />
        </li>
      </ul>
      <h5>
        <Trans id="Using our website and cookies" />
      </h5>
      <p>
        <Trans id="When you use/visit our Website, we may collect personal information. Our Web site does not identify each user personally by browsing information, but we may in some cases collect certain information about your use of our site: which pages you view, the time and date you visit, your IPn address. Cookies or other tracking tools are also used to improve our service. Cookies are a necessary file stored on your phone or PC to enable the company to identify you on different websites/services. Please consider that the service may not work properly if you disable cookies in your browser. Cookies are used to help collect personal data. Our company views cookies as any other personal information." />
      </p>
      <h5>
        <Trans id="New purposes" />
      </h5>
      <p>
        <Trans id="If our company wants to use your personal data for new purposes that are not described at the moment, we will provide you with a new notification describing the update." />
      </p>
      <h5>
        <Trans id="Testimonials" />
      </h5>
      <p>
        <Trans id="By using our website, you agree to the use of testimonials or reviews that you provided about the service. We may also use your testimonials or reviews for advertising purposes." />
      </p>
      <h5>
        <Trans id="Security" />
      </h5>
      <p>
        <Trans id="Data related to personal information may be stored in a physical/electronic copy or electronic format. Our company takes reasonable precautions to protect personal information. The company may use a variety of administrative, physical, and technical precautions if required to ensure security." />
      </p>
      <h5>
        <Trans id="Links" />
      </h5>
      <p>
        <Trans id="Our website" />{" "}
        <a href="https://thrivestore.co.uk">https://thrivestore.co.uk</a>{" "}
        <Trans id="may contain links to third-party websites. Such links may not be updated or maintained. We have no control over the sites, are not responsible for the content, privacy policies of the linked sites, and have no ownership rights in the linked sites unless otherwise specified. We recommend that you first read the privacy policy of such sites before you use them, as privacy policy may vary." />
      </p>

      <h5>
        <Trans id="Accessing or correcting your personal information" />
      </h5>
      <p>
        <Trans id="By contacting our support, you can get access to personal data that is currently stored. Also consider that in some cases we cannot give you access to your personal data. In such cases, we will explain the reason." />
      </p>

      <h5>
        <Trans id="Making a complaint" />
      </h5>
      <p>
        <Trans id="If you have a complaint or you think that we have violated privacy, you can contact us by email" />{" "}
        <a href="mailto:support@thrivestore.co.uk">support@thrivestore.co.uk</a>{" "}
        .
        <Trans id="Provide a detailed description of the complaint, your name, email address, phone number. Our support will respond to you within a reasonable amount of time." />
      </p>

      <h5>
        <Trans id="Changes to this Privacy Policy" />
      </h5>
      <p>
        <Trans id="Our Company reserves the right to revise this Policy. For example, in accordance with changes in the law, regulatory changes, new policies or the requirements of any authority. You should visit this page to review changes to the Privacy Policy." />
      </p>

      <h5>
        <Trans id="Contact Us" />
      </h5>
      <p>
        <Trans id="You can get more information about our policy, request access or correction of your personal information, or file a complaint by contacting us:" />{" "}
        <a href="mailto:support@thrivestore.co.uk">support@thrivestore.co.uk</a>
        .
      </p>
    </div>
  );
}
