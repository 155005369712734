import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

// context
import { errorContext } from "../../../context/error-provider/ErrorProvider";
import { couponContext } from "./../../../context/coupon-provider/CouponProvider";

export function useCartModalOrder() {
  const { error } = useContext(errorContext);
  const { checkCouponByCode } = useContext(couponContext);

  const [couponCode, setCouponCode] = useState("");

  const [couponDiscountPrice, setCouponDiscountPrice] = useState(0);

  const handleChangeCouponAdminData = useCallback(
    (
      e:
        | React.ChangeEvent<HTMLInputElement>
        | React.ChangeEvent<HTMLTextAreaElement>
    ) => {
      const { value } = e.target;

      setCouponCode(value);
    },
    [setCouponCode, error]
  );

  const onSubmit = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      try {
        if (couponCode) {
          const coupon = await checkCouponByCode(couponCode);

          if (coupon && coupon.isActivated) {
            setCouponDiscountPrice(Number(coupon.discount));
          } else {
            error("The coupon you entered does not exist");

            setCouponCode("");
            setCouponDiscountPrice(0);
          }
        }
      } catch (e) {
        error(e);
      }
    },
    [couponCode, setCouponDiscountPrice]
  );

  const isDisabledSubmitButton = useMemo(() => !couponCode, [couponCode]);

  return {
    couponCode,
    couponDiscountPrice,
    isDisabledSubmitButton,
    handleChangeCouponAdminData,
    onSubmit,
  };
}
