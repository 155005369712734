import React, { useContext, useEffect, useState } from "react";

// helpers
import { getBase64ImgSrc } from "../../../helpers/getBase64ImageSrc";

// context
import { cartContext } from "../../../context/cart-provider/CartProvider";

// images
import defaultBookCover from "../../../assets/images/default_book_cover.png";

// icons
import { CloseIcon } from "../../../assets/icons/CloseIcon";

// types
import type {
  CartBook,
  CartProduct,
} from "../../../context/cart-provider/CartProvider.types";

// styles
import styles from "./CartModalBook.module.scss";

type CartModalBookProps = {
  cartProduct: CartBook;
};

export function CartModalBook({ cartProduct }: CartModalBookProps) {
  const { getCartProducts, addQuantity, removeQuantity, deleteBookFromCart } =
    useContext(cartContext);

  const [qunatity, setQuantity] = useState(cartProduct.quantity);

  const addOneMoreBook = () => {
    setQuantity((prev) => prev + 1);
    addQuantity(cartProduct);
  };

  const removeOneBook = () => {
    setQuantity((prev) => prev - 1);
    removeQuantity(cartProduct);
  };

  useEffect(() => {
    getCartProducts();
  }, [qunatity]);

  return (
    <div className={styles.container}>
      <div>
        <img
          src={
            cartProduct.pictures[0]
              ? getBase64ImgSrc(cartProduct.pictures[0])
              : defaultBookCover
          }
          alt={cartProduct.title}
        />
      </div>
      <div className={styles.infoWrapper}>
        <h6>{cartProduct.title}</h6>
        <p>{cartProduct.description}</p>
      </div>
      <div className={styles.priceAndQuantityWrapper}>
        <div className={styles.pricesWrapper}>
          {cartProduct.discountPrice ? (
            <p>€{cartProduct.discountPrice}</p>
          ) : null}
          <div className={cartProduct.discountPrice ? styles.diagonalLine : ""}>
            <p className={cartProduct.discountPrice ? styles.oldPrice : ""}>
              €{cartProduct.price}
            </p>
          </div>
        </div>
        <div className={styles.quantityWrapper}>
          <p className={styles.quantityText}>Quantity</p>
          <div className={styles.counterWrapper}>
            <div className={styles.quantity}>{qunatity}</div>
            <div className={styles.marksWrapper}>
              <div className={styles.plus} onClick={addOneMoreBook}>
                +
              </div>
              <div className={styles.minus} onClick={removeOneBook}>
                -
              </div>
            </div>
          </div>
        </div>
        <div
          className={styles.removeWrapper}
          onClick={() => deleteBookFromCart(cartProduct.id)}
        >
          <CloseIcon color="#A21916" width={13} height={13} /> Remove
        </div>
      </div>
    </div>
  );
}
