import { z } from "zod";

export const sourceSchema = z.object({
  createdAt: z.string(),
  fileName: z.string(),
  fileSize: z.number(),
  key: z.string().nullable().optional(),
  extension: z.string(),
});

export const bookSchema = z.object({
  id: z.string(),
  title: z.string(),
  description: z.string(),
  tags: z.any(),
  category: z.enum([
    "business",
    "management",
    "motivation",
    "self-realization",
    "Time management",
    "",
  ]),
  author: z.string(),
  price: z.string(),
  discountPrice: z.string().optional().nullable(),
  pages: z.number(),
  pictures: z.array(z.string()),
  source: sourceSchema.optional().nullable(),
  rating: z.any(),
  isPublished: z.boolean(),
  createdAt: z.string(),
  updatedAt: z.string(),
});

export const bookAdminFormSchema = bookSchema.omit({
  id: true,
  createdAt: true,
  updatedAt: true,
});

export const allBooksSchema = z.object({
  total: z.number(),
  data: z.array(bookSchema),
});
