import React, { useContext, useEffect, useState } from "react";

// compoents
import { CircleWrapper } from "./circle-wrapper/CircleWrapper";
import { Introduction } from "./introduction/Introduction";
import { RatedBooks } from "./rated-books/RatedBooks";
import { Recomendations } from "./recomendations/Recomendations";
import { Discount } from "./discount/Discount";
import { Feedback } from "./feedback/Feedback";

// hooks
import { useMainScreenFetch } from "./use-main-screen";

// context
import { booksContext } from "../../../context/books-provider/BooksProvider";
import { collectionsContext } from "../../../context/collections-provider/CollectionsProvider";

// styles
import styles from "./Main.screen.module.scss";

export function MainScreen() {
  const { books } = useContext(booksContext);
  const { collectionsData } = useContext(collectionsContext);

  const { isMainScreenLoading } = useMainScreenFetch();

  return (
    <div className={styles.container}>
      <Introduction />

      <CircleWrapper>
        {/* Annual rating */}
        {isMainScreenLoading ? null : (
          <>
            {collectionsData && collectionsData.length ? (
              <RatedBooks
                title="Best collections"
                collections={collectionsData}
                type="mainCollections"
              />
            ) : null}

            {books && books.length ? (
              <>
                <RatedBooks
                  title="Top rated books of the 2024"
                  books={books}
                  type="mainBooks"
                />

                <Recomendations />

                {/* Monthly rating */}
                <RatedBooks
                  title="Top rated books of last month"
                  books={books}
                  type="mainBooks"
                />
              </>
            ) : null}
          </>
        )}
        <Discount />

        <Feedback />
      </CircleWrapper>
    </div>
  );
}
