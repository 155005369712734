export const PATHS = {
  // general
  index: "/",
  books: "/books",
  book: "/books/:bookId",
  collections: "/collections",
  collection: "/collections/:collectionId",
  bookWithGenre: "/books/genre/:genre",
  cookie: "/cookie",
  privacyPolicy: "/privacy-policy",
  termsAndConditions: "/terms-and-conditions",
  reset: "/reset",
  paymentSuccess: "/payment/success",
  paymentFailed: "/payment/failed",

  // guest
  login: "/login",
  signup: "/signup",

  // user
  profile: "/profile",
  history: "/history",

  // admin
  adminBooks: "/admin/books",
  adminCollections: "/admin/collections",
  adminCoupons: "/admin/coupons",
} as const;

export const routeControlsList: Array<string> = Object.keys(PATHS);
