import { z } from "zod";

// schemas

export const signUpUserSchema = z.object({
  username: z.string(),
  password: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  email: z.string(),
  phone: z.string(),
  avatar: z.string(),
});

export const logInUserSchema = z.object({
  password: z.string(),
  email: z.string(),
});

export const profileSchema = z.object({
  id: z.string(),
  email: z.string().optional().nullable(),
  username: z.string(),
  phone: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  role: z.enum(["user", "admin"]),
  status: z.enum(["active", "inactive"]),
  avatar: z.string().optional().nullable(),
  description: z.string(),
  balance: z.string().optional().nullable(),
  createdAt: z.string(),
  updatedAt: z.string(),
});

export const allUsersSchema = z.object({
  total: z.number(),
  data: z.array(profileSchema),
});

export const changeUserRoleSchema = z.object({
  email_id: z.string(),
  role: z.enum(["admin", "user"]),
});

export const updateProfileSchema = z.object({
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  phone: z.string().optional(),
  avatar: z.string().optional(),
  password: z.string().optional(),
  confirmPassword: z.string().optional(),
});

export const changePasswordFormSchema = z.object({
  password: z.string(),
  newPassword: z.string(),
});

export const resetPasswordFormSchema = z.object({
  email: z.string().optional(),
  password: z.string().optional(),
  confirmPassword: z.string().optional(),
  ressetCode: z.number().optional(),
});

export const feedbackFormSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  email: z.string(),
  phone: z.string(),
  message: z.string(),
});

export const courseFormSchema = z.object({
  email: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  socialMedia: z.string(),
  message: z.string(),
});
