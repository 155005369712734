// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GuestLayout_mainSection__43GRH {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.GuestLayout_backgroundImage__rruoj {
  position: absolute;
  top: 0;
  right: 0;
  width: 75%;
}

.GuestLayout_backgroundArrowImage__dQLOg {
  position: absolute;
  bottom: 20%;
  right: 0;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/layout/guest-layout/GuestLayout.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EAEA,aAAA;EACA,sBAAA;EACA,YAAA;AAAF;;AAGA;EACE,kBAAA;EACA,MAAA;EACA,QAAA;EACA,UAAA;AAAF;;AAGA;EACE,kBAAA;EACA,WAAA;EACA,QAAA;EACA,WAAA;AAAF","sourcesContent":[".mainSection {\n  position: relative;\n\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n}\n\n.backgroundImage {\n  position: absolute;\n  top: 0;\n  right: 0;\n  width: 75%;\n}\n\n.backgroundArrowImage {\n  position: absolute;\n  bottom: 20%;\n  right: 0;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainSection": `GuestLayout_mainSection__43GRH`,
	"backgroundImage": `GuestLayout_backgroundImage__rruoj`,
	"backgroundArrowImage": `GuestLayout_backgroundArrowImage__dQLOg`
};
export default ___CSS_LOADER_EXPORT___;
