import React, { SVGProps } from "react";

const EditIcon = ({
  className,
  color = "#FFFFFF",
  width = 24,
  height = 24,
  ...props
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      className={className}
      viewBox="0 0 21 20"
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.4518 3.73889L15.858 2.33265C16.4682 1.72245 17.4576 1.72245 18.0677 2.33265C18.6779 2.94284 18.6779 3.93216 18.0677 4.54235L9.21888 13.3912C8.77831 13.8318 8.23492 14.1556 7.6378 14.3335L5.40039 15L6.06687 12.7626C6.24475 12.1655 6.5686 11.6221 7.00916 11.1815L14.4518 3.73889ZM14.4518 3.73889L16.6504 5.93749M15.4004 11.6667V15.625C15.4004 16.6605 14.5609 17.5 13.5254 17.5H4.77539C3.73986 17.5 2.90039 16.6605 2.90039 15.625V6.87499C2.90039 5.83946 3.73986 4.99999 4.77539 4.99999H8.73372"
        stroke={color}
        strokeOpacity="0.6"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export { EditIcon };
