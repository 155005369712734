import React, { useCallback, useContext } from "react";

// components
import Button from "../../../../components/button/Button";

// context
import { cartContext } from "../../../../context/cart-provider/CartProvider";

// types
import type { BookType } from "../../../../context/books-provider/BooksProvider.types";
import type { CartProduct } from "../../../../context/cart-provider/CartProvider.types";

// styles
import styles from "./AddToCart.module.scss";
import { CollectionType } from "../../../../context/collections-provider/CollectionsProvider.types";

type AddToCartProps = {
  book?: BookType;
  collection?: CollectionType;
};

export function AddToCart({ book, collection }: AddToCartProps) {
  const { addBookToCart, addCollectionToCart } = useContext(cartContext);

  const isBooksAddToCart = !!book;

  const isCollectionsAddToCart = !!collection;

  const handleAddBookToCart = useCallback(
    (selectedBook: BookType) => {
      const bookWithQuantity = { ...selectedBook, quantity: 1 };

      addBookToCart(bookWithQuantity);
    },
    [addBookToCart]
  );

  const handleAddCollectionToCart = useCallback(
    (selectedCollection: CollectionType) => {
      addCollectionToCart(selectedCollection);
    },
    [addCollectionToCart]
  );

  return (
    <div className={styles.container}>
      <div className={styles.priceWrapper}>
        <div className={styles.newPriceWrapper}>
          <p>Price</p>
          {isBooksAddToCart ? (
            <p className={styles.newPrice}>
              €{book.discountPrice ? book.discountPrice : book.price}
            </p>
          ) : null}

          {isCollectionsAddToCart ? (
            <p className={styles.newPrice}>€{collection.price}</p>
          ) : null}
        </div>

        {isBooksAddToCart ? (
          book.discountPrice ? (
            <div className={styles.oldPriceWrapper}>
              <p>Last price</p>
              <div className={styles.diagonalLine}>
                <p className={styles.oldPrice}>€{book.price}</p>
              </div>
            </div>
          ) : null
        ) : null}
      </div>

      {isBooksAddToCart ? (
        <Button onClick={() => handleAddBookToCart(book)}>Add to cart</Button>
      ) : null}

      {isCollectionsAddToCart ? (
        <Button onClick={() => handleAddCollectionToCart(collection)}>
          Add to cart
        </Button>
      ) : null}
    </div>
  );
}
