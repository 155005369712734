import { Link } from "react-router-dom";
import { PATHS } from "../../route/route.controls";

export const footerData = [
  {
    title: "Contact us",
    items: [
      <a>Company name: Global Stride LTD</a>,
      <a>
        Address: 85 GREAT PORTLAND STREET FIRST FLOOR, LONDON, ENGLAND, W1W 7LT
      </a>,
      <a href="mailto:support@thrivestore.co.uk">support@thrivestore.co.uk</a>,
      <a href="tel:+441514570426">+441514570426</a>,
      "9:00 - 18:00 (Mon - Fri)",
    ],
  },

  {
    title: "Politics",
    items: [
      <Link to={PATHS.cookie}>Cookie</Link>,
      <Link to={PATHS.privacyPolicy}>Privacy Policy</Link>,
      <Link to={PATHS.termsAndConditions}>Terms and conditions</Link>,
    ],
  },
];
