import React from "react";
import classnames from "classnames";

// consts
import { AMOUNT_OF_ITEMS } from "./ItemsCounter.consts";

// styles
import styles from "./ItemsCounter.module.scss";

type ItemsCounterProps = {
  itemsAmount: number;
  setItemsAmount: React.Dispatch<React.SetStateAction<number>>;
};

export function ItemsCounter({
  itemsAmount,
  setItemsAmount,
}: ItemsCounterProps) {
  return (
    <div className={styles.itemsCounterWrapper}>
      <p>Show</p>
      <div className={styles.itemsCount}>
        {AMOUNT_OF_ITEMS.map((amount, index) => (
          <div
            className={classnames(
              styles.item,
              itemsAmount === amount ? styles.activeItem : ""
            )}
            key={index}
            onClick={() => setItemsAmount(amount)}
          >
            {amount}
          </div>
        ))}
      </div>
    </div>
  );
}
