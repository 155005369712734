import React, { SVGProps } from "react";

const LogoWithOutTextIcon = ({
  className,
  color = "#FDFDFD",
  width = 77,
  height = 77,
  ...props
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 77 77"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_622_479)">
        <path
          d="M23.065 15.4966H19.2311C18.2143 15.4966 17.2391 15.0927 16.5201 14.3737C15.8011 13.6547 15.3972 12.6795 15.3972 11.6627C15.3972 10.6459 15.8011 9.67077 16.5201 8.95178C17.2391 8.23279 18.2143 7.82886 19.2311 7.82886H61.4036V3.995C61.4036 2.97819 60.9997 2.00304 60.2807 1.28405C59.5617 0.565057 58.5865 0.161133 57.5697 0.161133L15.3972 0.161133C13.3636 0.161133 11.4133 0.968981 9.97532 2.40696C8.53734 3.84494 7.72949 5.79526 7.72949 7.82886V69.1707C7.72949 73.388 11.18 76.8384 15.3972 76.8384H61.4036C63.4372 76.8384 65.3875 76.0306 66.8255 74.5926C68.2635 73.1546 69.0713 71.2043 69.0713 69.1707V19.3305C69.0713 18.3137 68.6674 17.3385 67.9484 16.6195C67.2294 15.9005 66.2543 15.4966 65.2375 15.4966H38.4004V46.1675L30.7327 38.4998L23.065 46.1675V15.4966Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_622_479">
          <rect
            width="76.6773"
            height="76.6773"
            fill="white"
            transform="translate(0.0615234 0.161133)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export { LogoWithOutTextIcon };
