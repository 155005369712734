import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";

// icons
import { LogoWithOutTextIcon } from "../../../assets/icons/LogoWithOutTextIcon";
import { DeleteIcon } from "../../../assets/icons/DeleteIcon";

// hooks
import { useAuthForm } from "./use-auth-form";

//context
import { localesContext } from "../../../context/local-provider/LocalProvider";

// components
import Input from "../../../components/input/Input";
import Button from "../../../components/button/Button";
import Checkbox from "../../../components/checkbox/Checkbox";

// consts
import { PATHS } from "../../../route/route.controls";

// styles
import styles from "./AuthScreen.module.scss";

//translations
import { Trans } from "@lingui/react";
import { t } from "@lingui/macro";

type AuthScreenProps = {
  type: "login" | "signUp";
};

export function AuthScreen({ type }: AuthScreenProps) {
  const { i18n } = useContext(localesContext);

  const {
    authFormData,
    isChecked,
    fileInputRef,
    isDisabledButton,
    validatePassword,
    handleChangeAuthData,
    handleChangePhoneData,
    handleRemoveAvatar,
    onSubmit,
  } = useAuthForm(type);

  const navigate = useNavigate();

  const authTitle = type === "login" ? t(i18n)`Log In` : t(i18n)`Sign Up`;

  return (
    <section className={styles.container}>
      <div className={styles.aside}>
        <div className={styles.violetCircle} />

        <div className={styles.bigRedCircle} />

        <div
          className={styles.logoWrapper}
          onClick={() => navigate(PATHS.index)}>
          <LogoWithOutTextIcon color="#fff" />
          <p className={styles.asideTitle}>Thrivestore</p>
        </div>
        <div className={styles.info}>
          <p className={styles.description}>
            <Trans id="To bring about change, you must not be afraid to take the first step. We will fail when we fail to try" />
          </p>
          <h4 className={styles.author}>
            <Trans id="Rosa Parks" />
          </h4>
        </div>
      </div>
      <form className={styles.form}>
        <div className={styles.titleWrapper}>
          <div
            className={styles.formLogoWrapper}
            onClick={() => navigate(PATHS.index)}>
            <LogoWithOutTextIcon color="#000" />
            <p className={styles.formLogoTitle}>Thrivestore</p>
          </div>
          <p className={styles.title}>{authTitle}</p>
          <p className={styles.notification}>
            <Trans id="Please enter your details." />
          </p>
        </div>

        <div className={styles.inputs}>
          <div className={styles.inputWrapper}>
            <p>
              <Trans id="Email" />
            </p>
            <Input
              placeholder={t(i18n)`Enter your email address`}
              name="email"
              value={authFormData.email}
              onChange={handleChangeAuthData}
            />
          </div>

          <div className={styles.inputWrapper}>
            <p>
              <Trans id="Password" />
            </p>
            <Input
              type="password"
              placeholder={t(i18n)`Enter your password`}
              name="password"
              value={authFormData.password}
              onChange={handleChangeAuthData}
              validation={validatePassword()}
            />
          </div>

          {type === "signUp" ? (
            <>
              <div className={styles.inputWrapper}>
                <p>
                  <Trans id="Phone" />
                </p>
                <Input
                  type="phone"
                  placeholder={t(i18n)`Enter your phone`}
                  name="phone"
                  value={authFormData.phone}
                  onChange={handleChangePhoneData}
                />
              </div>

              <div className={styles.inputWrapper}>
                <p>
                  <Trans id="Username" />
                </p>
                <Input
                  placeholder={t(i18n)`Enter your username`}
                  name="username"
                  value={authFormData.username}
                  onChange={handleChangeAuthData}
                />
              </div>

              <div className={styles.inputWrapper}>
                <p>
                  <Trans id="First name" />
                </p>
                <Input
                  placeholder={t(i18n)`Enter your first name`}
                  name="firstName"
                  value={authFormData.firstName}
                  onChange={handleChangeAuthData}
                />
              </div>

              <div className={styles.inputWrapper}>
                <p>
                  <Trans id="Last name" />
                </p>
                <Input
                  placeholder={t(i18n)`Enter your last name`}
                  name="lastName"
                  value={authFormData.lastName}
                  onChange={handleChangeAuthData}
                />
              </div>

              <div className={styles.avatarWrapper}>
                <p>
                  <Trans id="Choose your avatar" />
                </p>

                {authFormData.avatar ? (
                  <div className={styles.pictureWrapper}>
                    <img
                      src={`data:image/png;base64, ${authFormData.avatar}`}
                      alt="Avatar"
                    />
                    <DeleteIcon
                      onClick={handleRemoveAvatar}
                      className={styles.deleteIcon}
                    />
                  </div>
                ) : (
                  <div className={styles.buttonWrapper}>
                    <input
                      type="file"
                      name="avatar"
                      accept="image/png, image/jpeg"
                      onChange={handleChangeAuthData}
                      ref={fileInputRef}
                      style={{ display: "none" }}
                    />

                    <Button
                      variant="avatar"
                      width="auto"
                      onClick={(e) => {
                        e.preventDefault();
                        fileInputRef.current?.click();
                      }}>
                      <Trans id="Choose avatar" />
                    </Button>

                    <p>
                      <Trans id="No file choosen" />
                    </p>
                  </div>
                )}

                <div className={styles.checkboxWrapper}>
                  <Checkbox
                    id="terms_and_conditions"
                    name="terms"
                    value="1"
                    checked={isChecked}
                    onChange={handleChangeAuthData}
                  />

                  <p className={styles.checkboxText}>
                    <Trans id="I hereby declare that I have read understood and accept the" />{" "}
                    <Link to={PATHS.termsAndConditions}>
                      <Trans id="T&C. See more" />
                    </Link>
                  </p>
                </div>
              </div>
            </>
          ) : null}

          <div>
            <Button
              onClick={(e) => onSubmit(e)}
              type="submit"
              disabled={isDisabledButton}>
              <Trans id="Submit" />
            </Button>
          </div>
        </div>

        {type === "signUp" ? (
          <p className={styles.question}>
            <Trans id="Already have an account?" />{" "}
            <Link to={PATHS.login}>
              <Trans id="Log In" />
            </Link>
          </p>
        ) : (
          <p className={styles.question}>
            <Trans id="Don’t have an account?" />{" "}
            <Link to={PATHS.signup}>
              <Trans id="Sign Up" />
            </Link>
          </p>
        )}
      </form>
    </section>
  );
}
