import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

// context
import { errorContext } from "../../../context/error-provider/ErrorProvider";
import { userContext } from "../../../context/user-provider/UserProvider";
import { localesContext } from "../../../context/local-provider/LocalProvider";

// types
import type { ProfileType } from "../../../context/user-provider/UserProvider.types";

//translations
import { t } from "@lingui/macro";

export function useProfileForm() {
  const { i18n } = useContext(localesContext);
  const { error, success } = useContext(errorContext);
  const { updateProfile, changePassword, userData } = useContext(userContext);

  const fileInputRef = useRef<any>(null);

  const [profileFormData, setProfileFormData] = useState<
    (ProfileType & { password?: string; newPassword?: string }) | null
  >(null);

  useEffect(() => {
    setProfileFormData(userData);
  }, [userData]);

  const handleChangeProfileData = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;

      if (name === "avatar") {
        const file = fileInputRef.current.files[0];

        const reader = new FileReader();
        reader.onload = () => {
          if (reader.result) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore

            const filString = reader.result as string;
            setProfileFormData((prev) => {
              if (!prev) return null;
              return {
                ...prev,
                [name]: filString.split(",")?.[1],
              };
            });
          }
        };

        reader.onerror = () => {
          error(
            t(
              i18n,
            )`A problem has arisen with the file, check the file and its format.`,
          );
        };

        if (reader) {
          reader.readAsDataURL(file);
        }
      }

      setProfileFormData((prev) => {
        if (!prev) return null;
        return {
          ...prev,
          [name]: value,
        };
      });
    },
    [i18n, setProfileFormData, error],
  );

  const handleChangeSelect = useCallback(
    (newValue: unknown) => {
      // TODO  Fix the react select types and remove this "as"
      const option = newValue as { label: string; value: string };

      setProfileFormData((prev) => {
        if (!prev) return null;
        return { ...prev, role: option.value as "user" | "admin" };
      });
    },
    [setProfileFormData],
  );

  const profileRoleOptions = useMemo(
    () => [
      { label: t(i18n)`User`, value: "user" },
      { label: t(i18n)`Admin`, value: "admin" },
    ],
    [i18n],
  );

  const onSubmit = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      try {
        if (profileFormData) {
          const updatedProfileFormData = {
            phone: profileFormData.phone,
            firstName: profileFormData.firstName,
            lastName: profileFormData.lastName,
            avatar: profileFormData.avatar || "",
          };

          await updateProfile(updatedProfileFormData);

          if (profileFormData.password && profileFormData.newPassword) {
            const passwordFormData = {
              password: profileFormData.password || "",
              newPassword: profileFormData.newPassword || "",
            };

            await changePassword(passwordFormData);
          }

          success(t(i18n)`The user has been successfully edited.`);
        }
      } catch (e) {
        error(e);
      } finally {
        setProfileFormData((prev) => {
          if (!prev) return null;
          return {
            ...prev,
            password: "",
            newPassword: "",
          };
        });
      }
    },
    [profileFormData, i18n, changePassword, error, success, updateProfile],
  );

  const handleRemoveAvatar = () => {
    setProfileFormData((prev) => {
      if (!prev) return null;
      return {
        ...prev,
        avatar: "",
      };
    });
  };

  const validatePassword = useCallback(
    (type: "password" | "newPassword") => {
      const passwordRegex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{7,30}$/;

      if (profileFormData) {
        const isValidPassword = passwordRegex.test(profileFormData[type] || "");

        if (isValidPassword || !profileFormData[type]) {
          return {
            error: false,
            message: "",
          };
        } else {
          return {
            error: true,
            message: t(
              i18n,
            )`Password must be more than 7 characters (letters + numbers), and must include at least one uppercase letter.`,
          };
        }
      }
    },
    [profileFormData, i18n],
  );

  const isDisabledSubmitButton = useMemo(
    () =>
      !profileFormData?.phone ||
      !profileFormData.firstName ||
      !profileFormData.lastName ||
      validatePassword("password")?.error ||
      validatePassword("newPassword")?.error,
    [profileFormData, validatePassword],
  );

  return {
    profileFormData,
    fileInputRef,
    isDisabledSubmitButton,
    profileRoleOptions,
    validatePassword,
    handleChangeProfileData,
    handleChangeSelect,
    handleRemoveAvatar,
    onSubmit,
  };
}

export function useProfileAdminFetch() {
  const { error } = useContext(errorContext);
  const { getProfile } = useContext(userContext);

  const [isProfileLoading, setIsProfileLoading] = useState(false);

  const getProfileData = async () => {
    try {
      setIsProfileLoading(true);

      await getProfile();
    } catch (err) {
      error(err);
    } finally {
      setIsProfileLoading(false);
    }
  };

  useEffect(() => {
    getProfileData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { isProfileLoading };
}
