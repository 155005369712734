import React, { useContext } from "react";

// carousel
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

// context
import { booksContext } from "../../../../context/books-provider/BooksProvider";

// compoentns
import { RecomendationItem } from "./recomandation-item/RecomendationItem";

// styles
import styles from "./Recomendations.module.scss";

type RecomendationsProps = {
  type?: "default" | "genre";
};

export function Recomendations({ type = "default" }: RecomendationsProps) {
  const { books } = useContext(booksContext);

  const currentBooks = books?.sort(() => Math.random() - 0.5).slice(0, 3);

  return (
    <section className={type === "default" ? styles.section : ""}>
      <div className={styles.contentWrapper}>
        {currentBooks ? (
          <Carousel
            showArrows={false}
            showStatus={false}
            showThumbs={false}
            autoPlay
            infiniteLoop
            className={styles.carouselWrapper}
          >
            {currentBooks.map((book, index) => (
              <RecomendationItem book={book} key={index} />
            ))}
          </Carousel>
        ) : null}
      </div>
    </section>
  );
}
