// layouts
import { UserLayout } from "../layout/user-layout/UserLayout";
import { GuestLayout } from "../layout/guest-layout/GuestLayout";
import { AdminLayout } from "../layout/admin-layout/AdminLayout";

// screens
import { MainScreen } from "../side/guest/main-screen/Main.screen";
import { BooksScreen } from "../side/general/books-screen/BooksScreen";
import { BookScreen } from "../side/general/book-screen/BookScreen";
import { CollectionsScreen } from "../side/general/collections-screen/CollectionsScreen";
import { CollectionScreen } from "../side/general/collection-screen/CollectionScreen";
import { PageNotFound } from "../side/guest/not-found/PageNotFound.screen";
import { ResetPasswordScreen } from "../side/general/reset-password-screen/ResetPasswordScreen";
import { CookieScreen } from "../side/general/cookie-screen/CookieScreen";
import { PrivacyPolicyScreen } from "../side/general/privacy-policy-screen/PrivacyPolicyScreen";
import { TermsAndConditionsScreen } from "../side/general/terms-and-conditions-screen/TermsAndConditionsScreen";
import { AuthScreen } from "../side/guest/auth-screen/AuthScreen";
import { ProfileAdminScreen } from "../side/user/profile-user-screen/ProfileUserScreen";
import { HistoryAdminScreen } from "../side/user/history-user-screen/HistoryUserScreen";
import { BooksAdminScreen } from "../side/admin/books-admin-screen/BooksAdminScreen";
import { CouponsAdminScreen } from "../side/admin/coupons-admin-screen/CouponsAdminScreen";
import { CollectionsAdminScreen } from "../side/admin/collections-admin-screen/CollectionsAdminScreen";
import { PaymentSuccess } from "../side/general/payment-success/PaymentSuccess";
import { PaymentFailed } from "../side/general/payment-failed/PaymentFailed";

// consts
import { PATHS } from "./route.controls";

export const guestRoutes = [
  {
    element: <GuestLayout />,
    children: [
      {
        path: PATHS.index,
        element: <MainScreen />,
        name: "MainScreen",
      },
      {
        path: PATHS.bookWithGenre,
        element: <BooksScreen />,
        name: "BooksScreen",
      },
      {
        path: PATHS.book,
        element: <BookScreen />,
        name: "BookScreen",
      },
      {
        path: PATHS.collections,
        element: <CollectionsScreen />,
        name: "CollectionsScreen",
      },
      {
        path: PATHS.collection,
        element: <CollectionScreen />,
        name: "CollectionScreen",
      },
      {
        path: PATHS.reset,
        element: <ResetPasswordScreen />,
        name: "ResetPasswordScreen",
      },
      {
        path: PATHS.cookie,
        element: <CookieScreen />,
        name: "CookieScreen",
      },
      {
        path: PATHS.privacyPolicy,
        element: <PrivacyPolicyScreen />,
        name: "PrivacyPolicyScreen",
      },
      {
        path: PATHS.termsAndConditions,
        element: <TermsAndConditionsScreen />,
        name: "TermsAndConditionsScreen",
      },
    ],
  },
  {
    path: PATHS.login,
    name: "Login",
    element: <AuthScreen type="login" />,
  },
  {
    path: PATHS.signup,
    name: "SignUp",
    element: <AuthScreen type="signUp" />,
  },
  {
    path: "*",
    name: "PageNotFound",
    element: <PageNotFound />,
  },
];

export const userRoutes = [
  {
    element: <GuestLayout />,
    children: [
      {
        path: PATHS.index,
        element: <MainScreen />,
        name: "MainScreen",
      },
      {
        path: PATHS.bookWithGenre,
        element: <BooksScreen />,
        name: "BooksScreen",
      },
      {
        path: PATHS.book,
        element: <BookScreen />,
        name: "BookScreen",
      },
      {
        path: PATHS.collections,
        element: <CollectionsScreen />,
        name: "CollectionsScreen",
      },
      {
        path: PATHS.collection,
        element: <CollectionScreen />,
        name: "CollectionScreen",
      },
      {
        path: PATHS.cookie,
        element: <CookieScreen />,
        name: "CookieScreen",
      },
      {
        path: PATHS.privacyPolicy,
        element: <PrivacyPolicyScreen />,
        name: "PrivacyPolicyScreen",
      },
      {
        path: PATHS.termsAndConditions,
        element: <TermsAndConditionsScreen />,
        name: "TermsAndConditionsScreen",
      },
      {
        path: PATHS.paymentSuccess,
        element: <PaymentSuccess />,
        name: "PaymentSuccess",
      },
      {
        path: PATHS.paymentFailed,
        element: <PaymentFailed />,
        name: "PaymentFailed",
      },
    ],
  },
  {
    element: <UserLayout />,
    children: [
      {
        path: PATHS.profile,
        element: <ProfileAdminScreen />,
        name: "ProfileScreen",
      },
      {
        path: PATHS.history,
        element: <HistoryAdminScreen />,
        name: "HistoryScreen",
      },
    ],
  },
  {
    path: "*",
    name: "PageNotFound",
    element: <PageNotFound />,
  },
];

export const adminRoutes = [
  {
    element: <GuestLayout />,
    children: [
      {
        path: PATHS.index,
        element: <MainScreen />,
        name: "MainScreen",
      },
      {
        path: PATHS.bookWithGenre,
        element: <BooksScreen />,
        name: "BooksScreen",
      },
      {
        path: PATHS.book,
        element: <BookScreen />,
        name: "BookScreen",
      },
      {
        path: PATHS.collections,
        element: <CollectionsScreen />,
        name: "CollectionsScreen",
      },
      {
        path: PATHS.collection,
        element: <CollectionScreen />,
        name: "CollectionScreen",
      },
      {
        path: PATHS.cookie,
        element: <CookieScreen />,
        name: "CookieScreen",
      },
      {
        path: PATHS.privacyPolicy,
        element: <PrivacyPolicyScreen />,
        name: "PrivacyPolicyScreen",
      },
      {
        path: PATHS.termsAndConditions,
        element: <TermsAndConditionsScreen />,
        name: "TermsAndConditionsScreen",
      },
      {
        path: PATHS.paymentSuccess,
        element: <PaymentSuccess />,
        name: "PaymentSuccess",
      },
      {
        path: PATHS.paymentFailed,
        element: <PaymentFailed />,
        name: "PaymentFailed",
      },
    ],
  },
  {
    element: <UserLayout />,
    children: [
      {
        path: PATHS.profile,
        element: <ProfileAdminScreen />,
        name: "ProfileScreen",
      },
      {
        path: PATHS.history,
        element: <HistoryAdminScreen />,
        name: "HistoryScreen",
      },
    ],
  },
  {
    element: <AdminLayout />,
    children: [
      {
        path: PATHS.adminBooks,
        element: <BooksAdminScreen />,
        name: "BooksAdminScreen",
      },
      {
        path: PATHS.adminCollections,
        element: <CollectionsAdminScreen />,
        name: "CollectionsAdminScreen",
      },
      {
        path: PATHS.adminCoupons,
        element: <CouponsAdminScreen />,
        name: "CouponsAdminScreen",
      },
    ],
  },
  {
    path: "*",
    name: "PageNotFound",
    element: <PageNotFound />,
  },
];
