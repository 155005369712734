import React, { FunctionComponent, useContext, useMemo, useState } from "react";

// context
import { apiContext } from "../api-provider/ApiProvider";

// consts
import { API_URL_COLLETIONS } from "./CollectionsProvider.consts";

// schemas
import {
  allCollectionsSchema,
  collectionSchema,
} from "./CollectionsProvider.schemas";

// types
import type {
  CollectionsContext,
  CollectionFormType,
  CollectionsProviderProps,
  CollectionType,
} from "./CollectionsProvider.types";
import { booksContext } from "../books-provider/BooksProvider";

export const collectionsContext = React.createContext({} as CollectionsContext);

export const CollectionsProvider: FunctionComponent<
  CollectionsProviderProps
> = (props) => {
  const { api } = useContext(apiContext);
  const { books } = useContext(booksContext);

  const { children } = props;

  const [collectionsData, setCollectionsData] = useState<
    CollectionType[] | null
  >(null);
  const [filteredCollections, setFilteredCollections] = useState<
    CollectionType[] | null
  >(null);
  const [total, setTotal] = useState(0);
  const [collectionById, setCollectionById] = useState<CollectionType | null>(
    null
  );

  const getCollections = async (
    limit?: number,
    offset?: number,
    isPublished?: boolean
  ) => {
    try {
      const apiURL = `${API_URL_COLLETIONS}?limit=${limit ? limit : 1000}${
        offset !== undefined ? `&offset=${offset}` : ""
      }${isPublished ? `&filter[isPublished]=${isPublished}` : ""}`;

      const allCollections = await api(apiURL, {}, allCollectionsSchema);

      if (allCollections) {
        setCollectionsData(allCollections.data);
        setTotal(allCollections.total);
      }
    } catch (error) {
      throw error;
    }
  };

  const getCollectionsByNameFilter = async (filterTitle?: string) => {
    try {
      const apiURL = `${API_URL_COLLETIONS}?limit=1000&filter[isPublished]=true${
        filterTitle ? `&filter[search]=${filterTitle}` : ""
      }`;

      if (filterTitle) {
        const allCollections = await api(apiURL, {}, allCollectionsSchema);

        if (allCollections) {
          return setFilteredCollections(allCollections.data);
        }
      }

      setFilteredCollections([]);
    } catch (error) {
      throw error;
    }
  };

  const getCollectionById = async (collectionId: string) => {
    try {
      const response = await api(
        `${API_URL_COLLETIONS}/${collectionId}`,
        {},
        collectionSchema
      );

      if (response) {
        const currentCollection = response;

        setCollectionById(currentCollection);
        return currentCollection;
      }

      return null;
    } catch (error) {
      throw error;
    }
  };

  const addCollection = async (formData: CollectionFormType) => {
    try {
      if (collectionsData) {
        const addedCollection = await api(
          API_URL_COLLETIONS,
          {
            method: "POST",
            data: formData,
          },
          collectionSchema
        );

        if (addedCollection) {
          const updatedCollections = [addedCollection, ...collectionsData];

          setCollectionsData(updatedCollections);
          return updatedCollections;
        }
      }

      return null;
    } catch (error) {
      throw error;
    }
  };

  const updateCollection = async (
    collectionId: string,
    formData: CollectionFormType
  ) => {
    try {
      if (collectionsData && books) {
        await api(`${API_URL_COLLETIONS}/${collectionId}`, {
          method: "PUT",
          data: formData,
        });

        const updatedCollections = collectionsData.map((collection) => {
          if (collection.id === collectionId) {
            const formatDataWithBooks = {
              title: formData.title,
              description: formData.description,
              price: `${formData.price}`,
              isPublished: formData.isPublished,
              books: books.filter((book) =>
                formData.ids.includes(book.id.toString())
              ),
              tags: formData.tags,
            };

            return { ...collection, ...formatDataWithBooks };
          }
          return collection;
        });

        setCollectionsData(updatedCollections);
        return updatedCollections;
      }

      return null;
    } catch (error) {
      throw error;
    }
  };

  const deleteCollection = async (collectionId: string) => {
    try {
      if (collectionsData) {
        await api(`${API_URL_COLLETIONS}/${collectionId}`, {
          method: "DELETE",
        });

        const updatedCollections = collectionsData.filter(
          (coupon) => coupon.id !== collectionId
        );

        setCollectionsData(updatedCollections);
        return updatedCollections;
      }

      return null;
    } catch (error) {
      throw error;
    }
  };
  const contextValue = useMemo(
    () => ({
      getCollections,
      getCollectionById,
      getCollectionsByNameFilter,
      addCollection,
      updateCollection,
      deleteCollection,
      collectionsData,
      filteredCollections,
      collectionById,
      total,
    }),
    [
      getCollections,
      getCollectionById,
      getCollectionsByNameFilter,
      addCollection,
      updateCollection,
      deleteCollection,
      collectionsData,
      filteredCollections,
      collectionById,
      total,
    ]
  );

  return (
    <collectionsContext.Provider value={contextValue}>
      {children}
    </collectionsContext.Provider>
  );
};
