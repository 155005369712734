import React, { useCallback } from "react";
import { generatePath, useNavigate } from "react-router-dom";

// components
import Button from "../../../../../components/button/Button";

// icons
import { ArrowRightIcon } from "../../../../../assets/icons/ArrowRightIcon";

// consts
import { PATHS } from "../../../../../route/route.controls";

// images
import defaultBookCover from "../../../../../assets/images/default_book_cover.png";

// helpers
import { getBase64ImgSrc } from "../../../../../helpers/getBase64ImageSrc";
import { truncateString } from "../../../../../helpers/truncate-string";

// types
import type { BookType } from "../../../../../context/books-provider/BooksProvider.types";

// styles
import styles from "./RecomendationItem.module.scss";

type RecomendationItemProps = {
  book: BookType;
};

export function RecomendationItem({ book }: RecomendationItemProps) {
  const navigate = useNavigate();

  const handleOpenCurrentBook = useCallback(
    (bookId: string) => {
      const generatedLink = generatePath(PATHS.book, {
        bookId,
      });

      navigate(generatedLink);
    },
    [navigate, PATHS]
  );

  return (
    <div className={styles.container}>
      <div className={styles.info}>
        <div className={styles.description}>
          <h2>
            <b>{book.title}</b> - {truncateString(book.description, 75)}{" "}
          </h2>
          <p>By {book.author}</p>
        </div>
        <div>
          <Button
            width="auto"
            icon={<ArrowRightIcon color="#fff" />}
            onClick={() => handleOpenCurrentBook(book.id)}
          >
            Buy now
          </Button>
        </div>
      </div>

      <div className={styles.imageWrapper}>
        <img
          src={
            book.pictures[0]
              ? getBase64ImgSrc(book.pictures[0])
              : defaultBookCover
          }
          alt={book.title}
        />
      </div>
    </div>
  );
}
