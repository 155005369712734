import React, { FunctionComponent, useContext, useMemo, useState } from "react";

// context
import { apiContext } from "../api-provider/ApiProvider";

// consts
import { API_URL_STORAGE } from "./StorageProvider.consts";

// schemas
import { fileDataSchema } from "./StorageProvider.schemas";

// types
import type {
  FileDataType,
  StorageContext,
  StorageProviderProps,
} from "./StorageProvider.types";

export const storageContext = React.createContext({} as StorageContext);

export const StorageProvider: FunctionComponent<StorageProviderProps> = (
  props
) => {
  const { api } = useContext(apiContext);

  const { children } = props;

  // TODO check the file type in online books project to remove the any type
  const [file, setFile] = useState<FileDataType | null>(null);
  const [uploadProgress, setUploadProgress] = useState(0);

  const getFileByKey = async (key: string) => {
    try {
      const response = await api(API_URL_STORAGE, {}, fileDataSchema);

      if (response) {
        const currentFile = response;

        setFile(currentFile);
        return currentFile;
      }

      return null;
    } catch (error) {
      throw error;
    }
  };

  const uploadFile = async (fileData: File) => {
    const data = new FormData();
    data.append("file", fileData);

    try {
      const uploadedFile = await api(
        API_URL_STORAGE,
        {
          method: "POST",
          data,
          onUploadProgress: (upload: any) =>
            setUploadProgress(Math.round((100 * upload.loaded) / upload.total)),
        },
        fileDataSchema,
        "multipart/form-data"
      );

      if (uploadedFile) {
        setFile(uploadedFile);
        return uploadedFile;
      }

      return null;
    } catch (error) {
      setUploadProgress(0);
      throw error;
    }
  };

  const deleteFile = async (key: string) => {
    try {
      await api(`${API_URL_STORAGE}?key=${key}`, {
        method: "DELETE",
      });
    } catch (error) {
      throw error;
    }
  };

  const contextValue = useMemo(
    () => ({
      file,
      uploadProgress,
      setUploadProgress,
      getFileByKey,
      uploadFile,
      deleteFile,
    }),
    [
      file,
      uploadProgress,
      setUploadProgress,
      getFileByKey,
      uploadFile,
      deleteFile,
    ]
  );

  return (
    <storageContext.Provider value={contextValue}>
      {children}
    </storageContext.Provider>
  );
};
