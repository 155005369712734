import { z } from "zod";

// schemas
import { bookSchema } from "../books-provider/BooksProvider.schemas";

export const collectionSchema = z.object({
  id: z.string(),
  title: z.string(),
  description: z.string(),
  books: z.array(bookSchema),
  tags: z.any(),
  price: z.string(),
  isPublished: z.boolean(),
  createdAt: z.string(),
  updatedAt: z.string(),
});

export const collectionFormSchema = z.object({
  title: z.string(),
  description: z.string(),
  tags: z.any(),
  price: z.number().nullable(),
  isPublished: z.boolean(),
  ids: z.array(z.string()),
});

export const allCollectionsSchema = z.object({
  total: z.number(),
  data: z.array(collectionSchema),
});
