import React, { SVGProps } from "react";

const PaginationArrowIcon = ({
  className,
  color = "#111726",
  width = 16,
  height = 17,
  ...props
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 16 17"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_686_677)">
        <path
          d="M12.5604 8.64018C12.5604 8.92693 12.4509 9.21365 12.2324 9.43227L5.35268 16.3119C4.91504 16.7495 4.20549 16.7495 3.76803 16.3119C3.33058 15.8745 3.33058 15.165 3.76803 14.7274L9.85558 8.64018L3.76825 2.55295C3.33079 2.11532 3.33079 1.40598 3.76825 0.968559C4.2057 0.530712 4.91525 0.530712 5.35289 0.968559L12.2326 7.84809C12.4512 8.06682 12.5604 8.35354 12.5604 8.64018Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_686_677">
          <rect
            width={width}
            height={height}
            fill="white"
            transform="translate(0 16.6401) rotate(-90)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export { PaginationArrowIcon };
