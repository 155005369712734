import { useNavigate } from "react-router-dom";

// consts
import { PATHS } from "../../../route/route.controls";

// components
import Button from "../../../components/button/Button";

// styles
import styles from "./PageNotFound.screen.module.scss";

//translations
import { Trans } from "@lingui/react";

export function PageNotFound() {
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <div className={styles.textInfo}>
        <h3>404</h3>
        <p>
          <Trans id="This page could not be found." />
        </p>
      </div>

      <div className={styles.buttonsWrapper}>
        <Button onClick={() => navigate(PATHS.index)}>
          <Trans id="Main page" />
        </Button>
      </div>
    </div>
  );
}
