import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// images
import defaultBookCover from "../../../assets/images/default_book_cover.png";

// hooks
import { useCollectionFetch } from "./use-collection";

// context
import { collectionsContext } from "../../../context/collections-provider/CollectionsProvider";

// consts
import { PATHS } from "../../../route/route.controls";

// components
import Spinner from "../../../components/spinner/Spinner";
import { AddToCart } from "./add-to-cart/AddToCart";

// helpers
import { getImageUrl } from "../../../helpers/getImageUrl";

// types
import type { BookType } from "../../../context/books-provider/BooksProvider.types";

// styles
import styles from "./CollectionScreen.module.scss";

//translations
import { Trans } from "@lingui/react";

export function CollectionScreen() {
  const { collectionById } = useContext(collectionsContext);
  const { isCollectionLoading } = useCollectionFetch();

  const navigate = useNavigate();

  const [selectedImage, setSelectedImage] = useState("");

  const collectionsBookImages = (books: BookType[]) => {
    return books.map((book) => book.picture || defaultBookCover);
  };

  useEffect(() => {
    if (collectionById?.books[0].picture) {
      return setSelectedImage(
        getImageUrl(collectionsBookImages(collectionById.books)[0]),
      );
    }
    setSelectedImage(defaultBookCover);
  }, [collectionById]);

  return (
    <section className={styles.container}>
      {isCollectionLoading ? (
        <div className={styles.spinner}>
          <Spinner color="dark" />
        </div>
      ) : collectionById ? (
        <div className={styles.circleWrapper}>
          {/* Backgrodund Circles */}
          <div className={styles.violetCircle} />

          <div className={styles.redCircle} />

          <div className={styles.redDownCircle} />

          <div className={styles.content}>
            <div className={styles.titleWrapper}>
              <p onClick={() => navigate(PATHS.collections)}>
                <Trans id="Collections" />
              </p>
              <h3>{collectionById.title}</h3>
            </div>

            <div className={styles.bookWrapper}>
              <div className={styles.bookMain}>
                <div className={styles.bookInfoWrapper}>
                  <div className={styles.imagesWrapper}>
                    <div className={styles.selectedImage}>
                      <img src={selectedImage} alt={collectionById.title} />
                    </div>
                    <div className={styles.imagesListWrapper}>
                      {collectionById.books.length ? (
                        collectionsBookImages(collectionById.books).map(
                          (picture, index) => (
                            <div
                              key={picture}
                              className={styles.pictureWrapper}>
                              <img
                                src={getImageUrl(picture)}
                                alt={`${index} book`}
                                onClick={() =>
                                  setSelectedImage(getImageUrl(picture))
                                }
                              />
                              {selectedImage === getImageUrl(picture) ? (
                                <div className={styles.pictureDivider} />
                              ) : null}
                            </div>
                          ),
                        )
                      ) : (
                        <div>
                          <img
                            src={defaultBookCover}
                            alt={`default cover`}
                            onClick={() => setSelectedImage(defaultBookCover)}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={styles.bookTypeWrapper}>
                    <div className={styles.bookType}>
                      <p className={styles.bookTypeText}>
                        <Trans id="Collection include" />
                      </p>
                      <p>
                        {collectionById.books.length} <Trans id="books" />
                      </p>
                    </div>

                    {collectionById.tags.length ? (
                      <div className={styles.bookType}>
                        <p className={styles.bookTypeText}>
                          <Trans id="Tags" />
                        </p>
                        <p>{collectionById.tags.join(", ")}</p>
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className={styles.divider} />

                <div className={styles.description}>
                  <h6>
                    <Trans id="Description" />
                  </h6>
                  <p>{collectionById.description}</p>
                </div>
              </div>

              <AddToCart collection={collectionById} />
            </div>
          </div>
        </div>
      ) : null}
    </section>
  );
}
