import React, { useState } from "react";
import ReactPaginate from "react-paginate";
import classnames from "classnames";

// icons
import { PaginationArrowIcon } from "../../assets/icons/PaginationArrow";

// styles
import styles from "./Pagination.module.scss";

type PaginationProps<T> = {
  variant?: "admin" | "user";
  amountOfItems: number;
  length: number;
  setItemOffset: React.Dispatch<React.SetStateAction<number>>;
};

export function Pagination<T>({
  variant = "user",
  amountOfItems,
  length,
  setItemOffset,
}: PaginationProps<T>) {
  const [currentPage, setCurrentPage] = useState(1);

  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  const itemsPerPage = amountOfItems;
  const pageCount = Math.ceil(length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % length;
    setCurrentPage(event.selected + 1);
    setItemOffset(newOffset);
  };

  const isUser = variant === "user";

  return (
    <div className={styles.container}>
      <ReactPaginate
        breakLabel="..."
        nextLabel={
          <div
            className={classnames(
              styles.arrow,
              isUser ? styles.paginationArrow : styles.adminPaginationArrow
            )}
          >
            <p>Next</p>
            <PaginationArrowIcon
              color={
                isUser
                  ? currentPage === pageCount
                    ? "#858585"
                    : "#111726"
                  : currentPage === pageCount
                  ? "#858585"
                  : "#ffffff"
              }
            />
          </div>
        }
        onPageChange={handlePageClick}
        pageLinkClassName={classnames(
          styles.link,
          isUser ? styles.pageLink : styles.adminPageLink
        )}
        activeLinkClassName={classnames(
          styles.activeLink,
          isUser ? styles.activeLinkClassName : styles.adminActiveLinkClassName
        )}
        pageRangeDisplayed={5}
        disabledLinkClassName={styles.disableArrow}
        pageCount={pageCount}
        className={styles.pagination}
        previousLabel={
          <div
            className={classnames(
              styles.arrow,
              isUser ? styles.paginationArrow : styles.adminPaginationArrow
            )}
          >
            <PaginationArrowIcon
              className={styles.previousArrow}
              color={
                isUser
                  ? currentPage === 1
                    ? "#858585"
                    : "#111726"
                  : currentPage === 1
                  ? "#858585"
                  : "#ffffff"
              }
            />
            <p>Prev</p>
          </div>
        }
        renderOnZeroPageCount={null}
      />
      <p className={styles.paginationDescription}>
        Showing {(currentPage - 1) * itemsPerPage + 1}-
        {Math.min(currentPage * itemsPerPage, length)} of {length} results
      </p>
    </div>
  );
}
