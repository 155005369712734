import React, { useContext } from "react";

// components
import { RatedBooks } from "../../guest/main-screen/rated-books/RatedBooks";
import { Pagination } from "../../../components/pagination/Pagination";
import Spinner from "../../../components/spinner/Spinner";

// context
import { booksContext } from "../../../context/books-provider/BooksProvider";
import { localesContext } from "../../../context/local-provider/LocalProvider";

// hooks
import { useBooksFetch } from "./use-books";

// styles
import styles from "./BooksScreen.module.scss";

//translations
import { Trans } from "@lingui/react";
import { t } from "@lingui/macro";

export function BooksScreen() {
  const { i18n } = useContext(localesContext);

  const { total, booksByGenre } = useContext(booksContext);

  const { isBooksLoading, bookTitle, setItemOffset } = useBooksFetch();

  return (
    <div className={styles.container}>
      <div className={styles.booksWrapper}>
        <div className={styles.orangeCircle} />

        <div className={styles.redCircle} />

        <div className={styles.redDownCircle} />

        <div className={styles.yellowCircle} />
        {isBooksLoading ? (
          <div className={styles.spinner}>
            <Spinner color="dark" />
          </div>
        ) : (
          <>
            {booksByGenre && booksByGenre.length ? (
              <>
                <RatedBooks
                  title={`${t(i18n)`Books about` + bookTitle()}`}
                  type="books"
                  books={booksByGenre}
                />
                <Pagination
                  amountOfItems={30}
                  length={total}
                  setItemOffset={setItemOffset}
                />
              </>
            ) : (
              <h4 className={styles.title}>
                <Trans id="Regrettably, there are currently no books on" />{" "}
                {bookTitle()}.
              </h4>
            )}
          </>
        )}
      </div>
    </div>
  );
}
