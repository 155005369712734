import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import type { ActionMeta } from "react-select";

// context
import { errorContext } from "../../../context/error-provider/ErrorProvider";
import { collectionsContext } from "../../../context/collections-provider/CollectionsProvider";
import { booksContext } from "../../../context/books-provider/BooksProvider";

// consts
import { INIT_COLLECTION_FORM } from "./CollectionsAdmin.consts";

// types
import type {
  CollectionFormType,
  CollectionType,
} from "../../../context/collections-provider/CollectionsProvider.types";

export function useCollectionAdminForm(
  modalType?: "create" | "edit" | null,
  collection?: CollectionType | null
) {
  const { error, success } = useContext(errorContext);
  const { addCollection, updateCollection, deleteCollection } =
    useContext(collectionsContext);
  const { books } = useContext(booksContext);

  const [collectionFormData, setCollectionFormData] =
    useState<CollectionFormType>(INIT_COLLECTION_FORM);

  useEffect(() => {
    if (collection && modalType === "edit") {
      const { title, description, tags, price, isPublished, books } =
        collection;

      const ids = books.map((book) => book.id);

      setCollectionFormData({
        title,
        description,
        tags,
        price: Number(price),
        isPublished,
        ids,
      });
    } else {
      setCollectionFormData(INIT_COLLECTION_FORM);
    }
  }, [collection, modalType]);

  const handleChangeCollectionData = useCallback(
    (
      e:
        | React.ChangeEvent<HTMLInputElement>
        | React.ChangeEvent<HTMLTextAreaElement>
    ) => {
      const { name, value } = e.target;

      if (name === "isPublished") {
        return setCollectionFormData((prev) => ({
          ...prev,
          isPublished: !prev.isPublished,
        }));
      }

      setCollectionFormData((prev) => ({ ...prev, [name]: value }));
    },
    [setCollectionFormData]
  );

  const handleChangeSelectData = useCallback(
    (newValue: unknown, actionMeta: ActionMeta<unknown>) => {
      if (Array.isArray(newValue)) {
        const multiOptions = newValue as { label: string; value: string }[];

        return setCollectionFormData((prev) => ({
          ...prev,
          [actionMeta.name || ""]: multiOptions.map(
            (multiOption) => multiOption.value
          ),
        }));
      }

      // TODO  Fix the react select types and remove this "as"
      const option = newValue as { label: string; value: string };

      setCollectionFormData((prev) => ({
        ...prev,
        [actionMeta.name || ""]: option.value,
      }));
    },
    [setCollectionFormData]
  );

  const handleRemoveCollection = useCallback(
    async (couponId?: string) => {
      try {
        if (couponId) {
          await deleteCollection(couponId);
        }

        success("The collection has been successfully deleted.");
      } catch (e) {
        throw e;
      }
    },
    [deleteCollection, success, error]
  );

  const onSubmit = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      try {
        // edit
        if (collection && modalType === "edit") {
          await updateCollection(collection.id, collectionFormData);
          return success("Collection successfully edited.");
        }

        // create
        await addCollection(collectionFormData);
        success("Collection successfully created.");
      } catch (e) {
        error(e);
      }
    },
    [
      collectionFormData,
      modalType,
      collection,
      addCollection,
      updateCollection,
      error,
      success,
    ]
  );

  const isDisabledSubmitButton = useMemo(
    () =>
      !collectionFormData.title ||
      !collectionFormData.description ||
      !collectionFormData.price ||
      !collectionFormData.ids.length,
    [collectionFormData]
  );

  const tagsCollectionsAdminOptions = useMemo(
    () => [
      { label: "Sponsored", value: "sponsored" },
      { label: "Authored", value: "authored" },
      { label: "Starred", value: "starred" },
    ],
    []
  );

  const booksCollectionsAdminOptions = useMemo(() => {
    if (books) {
      return books.map((book) => ({ label: book.title, value: book.id }));
    }
    return [{ label: "", value: "" }];
  }, [books]);

  return {
    collectionFormData,
    isDisabledSubmitButton,
    tagsCollectionsAdminOptions,
    booksCollectionsAdminOptions,
    setCollectionFormData,
    handleChangeCollectionData,
    handleChangeSelectData,
    handleRemoveCollection,
    onSubmit,
  };
}

export function useCollectionsAdminFetch() {
  const { error } = useContext(errorContext);
  const { getCollections } = useContext(collectionsContext);
  const { getBooks } = useContext(booksContext);

  const [isCollectionsLoading, setIsCollectionsLoading] = useState(true);

  const collectionsFetch = async () => {
    try {
      setIsCollectionsLoading(true);

      await getCollections();
      await getBooks(undefined, undefined, true);
    } catch (err) {
      error(err);
    } finally {
      setIsCollectionsLoading(false);
    }
  };

  useEffect(() => {
    collectionsFetch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { isCollectionsLoading };
}
