import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// context
import { errorContext } from "../../../context/error-provider/ErrorProvider";
import { collectionsContext } from "../../../context/collections-provider/CollectionsProvider";

// context
export function useCollectionFetch() {
  const { error } = useContext(errorContext);
  const { getCollectionById } = useContext(collectionsContext);

  const { collectionId } = useParams();

  const [isCollectionLoading, setIsCollectionLoading] = useState(false);

  const collectionsAdminFetch = async () => {
    try {
      if (collectionId) {
        setIsCollectionLoading(true);

        await getCollectionById(collectionId);
      }
    } catch (err) {
      error(err);
    } finally {
      setIsCollectionLoading(false);
    }
  };

  useEffect(() => {
    collectionsAdminFetch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isCollectionLoading,
  };
}
