import { useCallback, useContext, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

// context
import { errorContext } from "../../../context/error-provider/ErrorProvider";
import { userContext } from "../../../context/user-provider/UserProvider";

// consts
import { INIT_AUTH_FORM_DATA } from "./AuthScreen.consts";
import { PATHS } from "../../../route/route.controls";

// types
import type { SignUpUserType } from "../../../context/user-provider/UserProvider.types";

export function useAuthForm(type?: "login" | "signUp" | "reset") {
  const { error, success } = useContext(errorContext);
  const { signUp, logIn, logOut, resetPassword } = useContext(userContext);

  const navigate = useNavigate();

  const fileInputRef = useRef<any>(null);

  const [isChecked, setIsChecked] = useState(false);
  const [authFormData, setAuthFormData] =
    useState<SignUpUserType>(INIT_AUTH_FORM_DATA);

  const isLogin = type === "login";
  const isSignUp = type === "signUp";
  const isReset = type === "reset";

  const handleChangeAuthData = useCallback(
    (
      e:
        | React.ChangeEvent<HTMLInputElement>
        | React.ChangeEvent<HTMLTextAreaElement>
    ) => {
      const { name, value } = e.target;

      if (name === "avatar") {
        const file = fileInputRef.current.files[0];

        const reader = new FileReader();
        reader.onload = () => {
          if (reader.result) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore

            const filString = reader.result as string;
            setAuthFormData((prev) => ({
              ...prev,
              [name]: filString.split(",")?.[1],
            }));
          }
        };

        reader.onerror = () => {
          error(
            "A problem has arisen with the file, check the file and its format."
          );
        };

        if (reader) {
          reader.readAsDataURL(file);
        }
      }

      if (name === "terms") {
        return setIsChecked((prev) => !prev);
      }

      setAuthFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    },
    [setAuthFormData, setIsChecked, error]
  );

  const handleChangePhoneData = useCallback(
    (value: string) => {
      setAuthFormData((prev) => ({
        ...prev,
        phone: value,
      }));
    },
    [setAuthFormData]
  );

  const onSubmit = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      try {
        e.preventDefault();

        if (isSignUp) {
          await signUp(authFormData);
          success("You have been successfully registered. Please log in");

          navigate(PATHS.login);
          return null;
        }

        if (isLogin) {
          await logIn(authFormData);
          success("Welcome");

          navigate(PATHS.index);

          return null;
        }

        if (isReset) {
          const { email } = authFormData;
          await resetPassword({ email });
          success(`Reset password link has been sent to ${email}`);
          navigate(PATHS.index);

          return null;
        }
      } catch (e) {
        error(e);
      }
    },
    [
      isSignUp,
      isLogin,
      isReset,
      authFormData,
      error,
      navigate,
      resetPassword,
      success,
      signUp,
      logIn,
    ]
  );

  const handleRemoveAvatar = () => {
    setAuthFormData((prev) => ({
      ...prev,
      avatar: "",
    }));
  };

  const validatePassword = useCallback(() => {
    const passwordRegex =
      /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,20}$/;

    const isValid = passwordRegex.test(authFormData.password);

    if (isValid || !authFormData.password) {
      return {
        error: false,
        message: "",
      };
    } else {
      return {
        error: true,
        message:
          "Password must be more than 7 characters (letters + numbers), and must include at least one uppercase letter.",
      };
    }
  }, [authFormData]);

  const isDisabledAuthButton = useMemo(
    () =>
      !authFormData.email || !authFormData.password || validatePassword().error,
    [authFormData, validatePassword]
  );

  const isDisabledResetButton = useMemo(
    () => !authFormData.email,
    [authFormData]
  );

  const isDisabledRegisterButton = useMemo(
    () =>
      !authFormData.email ||
      !authFormData.password ||
      !authFormData.phone ||
      !authFormData.username ||
      !authFormData.firstName ||
      !authFormData.lastName ||
      !isChecked,
    [authFormData, isChecked]
  );

  const isDisabledButton = useMemo(() => {
    return isLogin
      ? isDisabledAuthButton
      : isSignUp
      ? isDisabledRegisterButton
      : isDisabledResetButton;
  }, [
    isLogin,
    isSignUp,
    isDisabledRegisterButton,
    isDisabledAuthButton,
    isDisabledResetButton,
  ]);

  const handleLogOut = useCallback(async () => {
    try {
      await logOut();
    } catch (e) {
      error(e);
    }
  }, [logOut, error]);

  return {
    authFormData,
    isChecked,
    fileInputRef,
    isDisabledButton,
    validatePassword,
    handleChangeAuthData,
    handleChangePhoneData,
    handleRemoveAvatar,
    onSubmit,
    handleLogOut,
  };
}
