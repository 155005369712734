import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

// components
import App from "./App";

// context
import { ErrorProvider } from "./context/error-provider/ErrorProvider";
import { ApiProvider } from "./context/api-provider/ApiProvider";
import { UserProvider } from "./context/user-provider/UserProvider";
import { SessionProvider } from "./context/session-provider/SessionProvider";
import { CouponProvider } from "./context/coupon-provider/CouponProvider";
import { OrderProvider } from "./context/order-provider/OrderProvider";
import { CartProvider } from "./context/cart-provider/CartProvider";
import { BooksProvider } from "./context/books-provider/BooksProvider";
import { StorageProvider } from "./context/storage-provider/StorageProvider";
import { PaymentProvider } from "./context/payement-provider/PaymentProvider";

// styles
import "./styles/globals.scss";
import { CollectionsProvider } from "./context/collections-provider/CollectionsProvider";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <ErrorProvider>
      <SessionProvider>
        <ApiProvider>
          <UserProvider>
            <BooksProvider>
              <CouponProvider>
                <CartProvider>
                  <CollectionsProvider>
                    <OrderProvider>
                      <PaymentProvider>
                        <StorageProvider>
                          <App />
                        </StorageProvider>
                      </PaymentProvider>
                    </OrderProvider>
                  </CollectionsProvider>
                </CartProvider>
              </CouponProvider>
            </BooksProvider>
          </UserProvider>
        </ApiProvider>
      </SessionProvider>
    </ErrorProvider>
  </BrowserRouter>
  // </React.StrictMode>
);
