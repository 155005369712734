import React, { useContext } from "react";
import classnames from "classnames";

// components
import Button from "../../../../components/button/Button";
import Input from "../../../../components/input/Input";
import Textarea from "../../../../components/textarea/Textarea";

//context
import { localesContext } from "../../../../context/local-provider/LocalProvider";

// icons
import { ArrowRightIcon } from "../../../../assets/icons/ArrowRightIcon";

// hooks
import { useFeedbackModalForm } from "./use-feedback-modal-form";

// styles
import styles from "./Feedback.module.scss";

//translations
import { Trans } from "@lingui/react";
import { t } from "@lingui/macro";

export function Feedback() {
  const { i18n } = useContext(localesContext);

  const {
    feedbackFormData,
    isDisabledSubmitButton,
    handleChangeFeedbackData,
    onSubmit,
  } = useFeedbackModalForm();

  return (
    <section className={styles.container}>
      <div className={styles.contentWrapper}>
        <h3>
          <Trans id="Send us a message" />
        </h3>
        <form className={styles.form}>
          <div className={styles.formHeader}>
            <div className={classnames(styles.icon, styles.closeIcon)} />
            <div className={classnames(styles.icon, styles.turnIcon)} />
            <div className={classnames(styles.icon, styles.expandIcon)} />
          </div>
          <div className={styles.formBody}>
            <div className={styles.inputWrapper}>
              <div className={styles.inputGroup}>
                <Input
                  variant="secondary"
                  placeholder={t(i18n)`Your first name`}
                  name="firstName"
                  value={feedbackFormData.firstName}
                  onChange={handleChangeFeedbackData}
                />
                <Input
                  variant="secondary"
                  placeholder={t(i18n)`Your last name`}
                  name="lastName"
                  value={feedbackFormData.lastName}
                  onChange={handleChangeFeedbackData}
                />
              </div>
              <div className={styles.inputGroup}>
                <Input
                  variant="secondary"
                  placeholder={t(i18n)`Your email`}
                  name="email"
                  value={feedbackFormData.email}
                  onChange={handleChangeFeedbackData}
                />
                <Input
                  variant="secondary"
                  placeholder={t(i18n)`Your phone`}
                  name="phone"
                  value={feedbackFormData.phone}
                  onChange={handleChangeFeedbackData}
                />
              </div>
            </div>
            <div>
              <Textarea
                id={"feedback_message"}
                placeholder={t(i18n)`How can we help you?`}
                name="message"
                value={feedbackFormData.message}
                onChange={handleChangeFeedbackData}
              />
            </div>
            <div className={styles.buttonWrapper}>
              <Button
                width="auto"
                disabled={isDisabledSubmitButton}
                onClick={onSubmit}
                icon={<ArrowRightIcon color="#FFF" />}>
                <Trans id="Send" />
              </Button>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
}
