import { useCallback } from "react";
import { generatePath, useNavigate } from "react-router-dom";

// components
import Button from "../../../../../components/button/Button";

// icons
import { ArrowRightIcon } from "../../../../../assets/icons/ArrowRightIcon";

// consts
import { PATHS } from "../../../../../route/route.controls";

// helpers
import { truncateString } from "../../../../../helpers/truncate-string";
import { getImageUrl } from "../../../../../helpers/getImageUrl";

// types
import type { BookType } from "../../../../../context/books-provider/BooksProvider.types";

// styles
import styles from "./RecomendationItem.module.scss";

//translations
import { Trans } from "@lingui/react";

type RecomendationItemProps = {
  book: BookType;
};

export function RecomendationItem({ book }: RecomendationItemProps) {
  const navigate = useNavigate();

  const handleOpenCurrentBook = useCallback(
    (bookId: string) => {
      const generatedLink = generatePath(PATHS.book, {
        bookId,
      });

      navigate(generatedLink);
    },
    [navigate, PATHS],
  );

  return (
    <div className={styles.container}>
      <div className={styles.info}>
        <div className={styles.description}>
          <h2>
            <b>{book.title}</b> - {truncateString(book.description, 75)}{" "}
          </h2>
          <p>
            <Trans id="By" /> {book.author}
          </p>
        </div>
        <div>
          <Button
            width="auto"
            icon={<ArrowRightIcon color="#fff" />}
            onClick={() => handleOpenCurrentBook(book.id)}>
            <Trans id="Buy now" />
          </Button>
        </div>
      </div>

      <div className={styles.imageWrapper}>
        <img src={getImageUrl(book?.picture)} alt={book.title} />
      </div>
    </div>
  );
}
