import React, { useContext } from "react";

// hooks
import { useHistoryUserFetch } from "./use-history-admin";

// helpers
import { getBase64ImgSrc } from "../../../helpers/getBase64ImageSrc";

// images
import defaultBookCover from "../../../assets/images/default_book_cover.png";

// context
import { orderContext } from "../../../context/order-provider/OrderProvider";

// components
import Spinner from "../../../components/spinner/Spinner";

// styles
import styles from "./HistoryUserScreen.module.scss";
import { formattedDate } from "../../../helpers/format-date";

export function HistoryAdminScreen() {
  const { ordersData } = useContext(orderContext);

  const { isHistoryLoading } = useHistoryUserFetch();

  return (
    <section className={styles.container}>
      {isHistoryLoading ? (
        <Spinner />
      ) : ordersData && ordersData.length ? (
        <div className={styles.tableWrapper}>
          <div className={styles.tableRowHead}>
            <div>Photo</div>
            <div>Title</div>
            <div>Description</div>
            <div>Type</div>
            <div>Tags</div>
            <div>Price</div>
            <div>Discount Price</div>
            <div>Status</div>
            <div>Date</div>
          </div>

          {ordersData.map((order) => (
            <div className={styles.tableRow} key={order.id}>
              {order.books.map((book) => (
                <div className={styles.tableRowBody} key={book.id}>
                  <div className={styles.imageWrapper}>
                    <img
                      src={
                        book.pictures[0]
                          ? getBase64ImgSrc(book.pictures[0])
                          : defaultBookCover
                      }
                      alt="course"
                    />
                  </div>
                  <div>{book.title}</div>
                  <div>{book.description}</div>
                  <div>{book.category}</div>
                  <div>{book.tags}</div>
                  <div>{book.price}</div>
                  <div>{book.discountPrice}</div>
                  <div>{book.isPublished ? "Yes" : "No"}</div>
                  <div>{formattedDate(order.updatedAt)}</div>
                </div>
              ))}
            </div>
          ))}
        </div>
      ) : (
        <h4 className={styles.title}>
          Regrettably, there are currently no history.
        </h4>
      )}
    </section>
  );
}
