import React, { FunctionComponent, useContext, useMemo, useState } from "react";

// context
import { apiContext } from "../api-provider/ApiProvider";

// consts
import { API_URL_BOOK } from "./BooksProvider.consts";

// schemas
import { allBooksSchema, bookSchema } from "./BooksProvider.schemas";

// helpers
import { handleErrorWithMessages } from "../error-provider/error";

// types
import type {
  BookAdminFormType,
  BookType,
  BooksContext,
  BooksProviderProps,
} from "./BooksProvider.types";

export const booksContext = React.createContext({} as BooksContext);

export const BooksProvider: FunctionComponent<BooksProviderProps> = (props) => {
  const { api } = useContext(apiContext);

  const { children } = props;

  const [books, setBooks] = useState<BookType[] | null>(null);
  const [booksByGenre, setBooksByGenre] = useState<BookType[] | null>(null);
  const [filteredBooks, setFilteredBooks] = useState<BookType[] | null>(null);
  const [total, setTotal] = useState(0);
  const [bookById, setBookById] = useState<BookType | null>(null);

  const getBooks = async (
    limit?: number,
    offset?: number,
    isPublished?: boolean
  ) => {
    try {
      const apiURL = `${API_URL_BOOK}?limit=${limit ? limit : 1000}${
        offset !== undefined ? `&offset=${offset}` : ""
      }${isPublished ? `&filter[isPublished]=${isPublished}` : ""}`;

      const allBooks = await api(apiURL, {}, allBooksSchema);

      if (allBooks) {
        setBooks(allBooks.data);
        setTotal(allBooks.total);
      }
    } catch (error) {
      throw error;
    }
  };

  const getBooksByNameFilter = async (filterTitle?: string) => {
    try {
      const apiURL = `${API_URL_BOOK}?limit=1000&filter[isPublished]=true${
        filterTitle ? `&filter[search]=${filterTitle}` : ""
      }`;

      if (filterTitle) {
        const allBooks = await api(apiURL, {}, allBooksSchema);

        if (allBooks) {
          return setFilteredBooks(allBooks.data);
        }
      }

      setFilteredBooks([]);
    } catch (error) {
      throw error;
    }
  };

  const getBooksByGenre = async (
    category: string,
    limit?: number,
    offset?: number
  ) => {
    try {
      const apiURL = `${API_URL_BOOK}?limit=${limit ? limit : 1000}${
        offset !== undefined ? `&offset=${offset}` : ""
      }&filter[isPublished]=true&filter[category]=${category}`;

      const allBooks = await api(apiURL, {}, allBooksSchema);

      if (allBooks) {
        setBooksByGenre(allBooks.data);
        setTotal(allBooks.total);
      }
    } catch (error) {
      throw error;
    }
  };

  const getBookById = async (bookId: string) => {
    try {
      const response = await api(`${API_URL_BOOK}/${bookId}`, {}, bookSchema);

      if (response) {
        const currentCourse = response;

        setBookById(currentCourse);
        return currentCourse;
      }

      return null;
    } catch (error) {
      throw error;
    }
  };

  const addBook = async (formData: BookAdminFormType) => {
    try {
      if (books) {
        const addedBook = await api(
          API_URL_BOOK,
          {
            method: "POST",
            data: formData,
          },
          bookSchema
        );

        if (addedBook) {
          const updatedBooks = [addedBook, ...books];

          setBooks(updatedBooks);
          return updatedBooks;
        }
      }

      return null;
    } catch (error) {
      throw handleErrorWithMessages(error, {
        "request entity too large":
          "Sorry, it seems you've tried to upload files that are too large. Please replace them with smaller files and try again.",
      });
    }
  };

  const updateBook = async (bookId: string, formData: BookAdminFormType) => {
    try {
      if (books) {
        await api(`${API_URL_BOOK}/${bookId}`, {
          method: "PUT",
          data: formData,
        });

        const updatedBooks = books.map((book) => {
          if (book.id === bookId) {
            return { ...book, ...formData };
          }
          return book;
        });

        setBooks(updatedBooks);
        return updatedBooks;
      }

      return null;
    } catch (error) {
      throw error;
    }
  };

  const deleteBook = async (bookId: string) => {
    try {
      if (books) {
        await api(`${API_URL_BOOK}/${bookId}`, {
          method: "DELETE",
        });
        const updatedBooks = books.filter((book) => book.id !== bookId);

        setBooks(updatedBooks);
        return updatedBooks;
      }

      return null;
    } catch (error) {
      throw error;
    }
  };

  const contextValue = useMemo(
    () => ({
      books,
      booksByGenre,
      filteredBooks,
      total,
      bookById,
      getBooks,
      getBooksByGenre,
      getBooksByNameFilter,
      getBookById,
      addBook,
      updateBook,
      deleteBook,
    }),
    [
      books,
      booksByGenre,
      filteredBooks,
      total,
      bookById,
      getBooks,
      getBooksByGenre,
      getBooksByNameFilter,
      getBookById,
      addBook,
      updateBook,
      deleteBook,
    ]
  );

  return (
    <booksContext.Provider value={contextValue}>
      {children}
    </booksContext.Provider>
  );
};
