import { CollectionFormType } from "../../../context/collections-provider/CollectionsProvider.types";

export const INIT_COLLECTION_FORM: CollectionFormType = {
  title: "",
  description: "",
  tags: [],
  price: null,
  isPublished: false,
  ids: [],
};
