import React from "react";

// styles
import styles from "../General.module.scss";

export function TermsAndConditionsScreen() {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h4>TERMS AND CONDITIONS</h4>
        <p>09.05.2024</p>
        <p>
          <strong>
            Your access and use of{" "}
            <a href="https://thrivestore.co.uk">https://thrivestore.co.uk </a>{" "}
            and its Services constitutes your agreement to be bound by these
            Terms. You understand and agree that the Service is provided on an
            AS IS and AS AVAILABLE basis as amended from time to time.
          </strong>
        </p>
        <p>
          These Terms outline the terms and conditions under which{" "}
          <strong>Global Stride LTD</strong>, a company duly organized and
          established under the laws of England, bearing company registration
          number <strong>15700207</strong>, and having its registered address at{" "}
          <strong>
            85 Great Portland Street, First Floor, London, England, W1W 7LT
          </strong>{" "}
          (“We”, "Company") will provide Services to the You.
        </p>
      </div>

      <p>
        The current Terms and Conditions establish the basis for visiting our
        website and ordering services. By purchasing our services, the User
        acknowledges that they have read and agreed to comply with our Terms and
        Conditions, which include our privacy policy. If you do not agree with
        our Terms and Conditions, please do not place any orders.
      </p>

      <h5>General Provisions</h5>
      <p>
        We offer book purchasing service (“Service”), which may include buying
        various types of digital books and educational materials. Our Services
        are provided to individuals, businesses, and organizations that require
        different types of literature.
      </p>

      <h5>Order Placement and Payment Process</h5>
      <p>
        When the user (“User”) visits our website and wishes to place an order,
        he/she needs to create a Personal Account (“Account”), where they must
        provide personal information (name, email address, phone number, payment
        method).
      </p>
      <p>
        After adding the product to your cart and placing your order, you can
        proceed to payment. All Services are paid at 100%.
      </p>
      <p>
        Before completing your purchase, you will be given the opportunity to
        review your order and amend it. Please ensure that you have checked your
        order carefully before submitting it.
      </p>
      <p>
        After that the User will be provided with access to digital books. By
        making a payment in Account, the User acknowledges and agrees to these
        Terms and Conditions and all other policies of our website.
      </p>
      <p>
        When you make a purchase, you agree not to use an invalid or
        unauthorized payment method. If your payment method fails and you still
        get access to the digital books you are enrolling in, you agree to pay
        us the corresponding fees within 15 days of notification from us. We
        reserve the right to disable access to any content for which we have not
        received adequate payment.
      </p>
      <p>
        If you do not give us the accurate or complete information (name, email
        address, phone number, payment method, type of the book that you order)
        within a reasonable time of our request, we will cancel your order. We
        will not be responsible for any delay in the availability of the Service
        that results from you providing incorrect or incomplete information.
      </p>
      <h5>Cancellation Policy</h5>
      <p>
        The cost of the order is calculated on the type of a book and
        educational materials.
      </p>
      <p>
        We only consider complaints if an email is sent within seven working
        days from the date of completion of the order and receiving access to
        digital books/ educational materials. We are not liable in case of any
        delays caused by malfunctioning modems, emails, or other mail beyond our
        objective control.
      </p>
      <p>
        The agreement comes into force after the User has paid for it. The
        courts of England have jurisdiction over all disputes. We reserve the
        right to demand payment of work and compensation from the client.
      </p>

      <h5>Refunds</h5>
      <p>Refunds are made to the card or Account of the User.</p>
      <p>
        The User can request a refund only if the request is made within one
        week of the completion of the order. If this is the case, write to us at{" "}
        <a href="mailto:support@thrivestore.co.uk">support@thrivestore.co.uk</a>{" "}
        and tell us your reasons for requesting a refund.
      </p>
      <p>
        Keep in mind that <strong>Global Stride LTD</strong> will not refund
        money if you are not satisfied with the type or style of the digital
        books/ educational material. We provide refunds in case of a mistake: if
        you received another Service for any technical reason.
      </p>
      <p>
        At our discretion, if we believe you are abusing our refund policy, such
        as if you’ve consumed a significant portion of the content that you want
        to refund or if you’ve previously refunded the content, we reserve the
        right to deny your refund, restrict you from other future refunds, ban
        your Account, and/or restrict all future use of the Services.
      </p>

      <h5>Limitation of Liability</h5>
      <p>
        You expressly agree that your use of, or inability to use, the Services
        is at your sole risk. You are solely responsible for any illegal use of
        our Services.
      </p>
      <p>
        We do not guarantee, represent or warrant that your use of our Services
        will be uninterrupted, timely, secure or error-free.
      </p>
      <p>
        We do not warrant that the results that may be obtained from the use of
        the Services will be accurate or reliable.
      </p>
      <h5>Intellectual Property</h5>
      <p>
        The work provided by our website is subject to copyright and other
        intellectual property rights. Our Services are intended for personal use
        in the case of:
      </p>
      <ol>
        <li>you use the work as a guide to preparing for your work;</li>
        <li>If you provide our site as a source of work;</li>
        <li>
          you do not submit our digital books and educational materials as your
          own. Use in any way: copying, reproducing, distributing, broadcasting,
          selling, displaying, licensing, or otherwise exploiting is prohibited
          without prior written agreement.
        </li>
      </ol>
      <p>
        You agree not to distribute or copy our work, except for those
        stipulated in the current Terms and Conditions.
      </p>

      <h5>Restrictions on Use</h5>
      <p>
        Using our Services does not give you ownership of any intellectual
        property rights in our Services or the content you access. Except in the
        context of browsing or accessing our Services in accordance with these
        Terms, you may not use content from our Services unless you obtain
        written permission from its owner or are otherwise permitted by law.
      </p>
      <p>
        You may not translate, adapt, create derivative works, reverse-code,
        decompile, seek to find the source code of the platform, or otherwise
        use the Services in any way that violates intellectual property and/or
        Terms and Conditions.
      </p>
      <p>
        You agree not to: (a) use our Services in any way other than as
        specified in the agreement.; (b) use our Services in any way that
        violates UK or local law; (c) access or attempt to access our Services
        at any time; (d) copy/reproduce the digital books/ educational materials
        that is part of our Services; (e) modify, copy, reverse engineer,
        modify, decompile, reassemble, and create derivative works of our
        Services; (f) interfere in any way with us; (g) sublicense, distribute,
        share, assign, sell, rent, grant access, lease, rent, grant a security
        interest in, transfer our Services or your right to use the Services to
        any person/entity; (h) extract, copy, store, modify, delete data and
        also embedded/provided/available information of our services; (i) or
        data available through our service to any other person and to use it for
        any purpose other than your internal purposes and/or as expressly
        permitted by these Terms and Conditions.
      </p>
      <h5>Third-Party Links</h5>
      <p>
        Our website may contain links to other websites/online Services
        maintained by third parties that are not maintained/controlled by us.
        These links do not imply that we endorse the people associated with such
        websites or endorse the content posted on such sites. We are not
        responsible for the content posted on such sites and are not responsible
        for the services, products, or content of such websites/online services.
        Other sites/online Services are not part of our company. Such other
        sites/online Services are not subject to these Terms and Conditions. In
        case you use these other websites, we advise you to read the Terms and
        Conditions of these other websites as you use them at your own risk. By
        using our service, you accept that we are not responsible for any
        damage/any loss incurred as a result of using any other online Services
        or websites.
      </p>

      <h5>Applicable Law</h5>
      <p>
        All disputes (“claims”) or causes of action are governed by the laws of
        England, regardless of where you access our site or your origin. You and
        we agree that all claims arising out of the Terms and Conditions shall
        be litigated in England unless otherwise agreed by the parties. We will
        make every effort to resolve any problems between you and us in a
        dispute.
      </p>

      <h5>Cookie Policy</h5>
      <p>
        We use cookies on our website. You can read more about what cookies we
        use and all other details in our Cookies Policy or you can contact us if
        you have any questions. Feel free to email us at{" "}
        <a href="mailto:support@thrivestore.co.uk">support@thrivestore.co.uk</a>
      </p>

      <h5>Modifications</h5>
      <p>
        The current Terms and Conditions will be updated from time to time. We
        cannot notify all our Users of any changes. Therefore, by agreeing with
        our Terms and Conditions you agree to check this page from time to time.
        The agreement is subject to change at any time, and changes will be
        regulated by the current Terms and Conditions.
      </p>

      <h5>Join our team!</h5>
      <p>
        We currently have available positions for sales managers, and you can
        apply today! To apply, simply reach out to our managers via the contact
        form on our website or email us, and we will send you a test assignment.
      </p>
    </div>
  );
}
