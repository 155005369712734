import React, { useContext, useEffect } from "react";
import { useRoutes } from "react-router-dom";

// context
import { sessionContext } from "./context/session-provider/SessionProvider";

// routes
import { guestRoutes, userRoutes, adminRoutes } from "./route/routes";

// components
import { ScrollToTop } from "./components/scroll-to-top/ScrollToTop";

// helpers
import { validateAndClearCartProduct } from "./helpers/validateAndClearCartProduct";

// consts
import { CART } from "./context/cart-provider/CartProvider.consts";

// styles
import styles from "./App.module.scss";

function App() {
  const { getRoleFromToken } = useContext(sessionContext);
  const role = getRoleFromToken();

  const isAdmin = role === "admin";
  const isUser = role === "user";

  const routes = isAdmin ? adminRoutes : isUser ? userRoutes : guestRoutes;
  const routesMain = useRoutes(routes);

  useEffect(() => {
    validateAndClearCartProduct(CART);
  }, []);

  return (
    <div className={styles.container}>
      <>
        {routesMain} <ScrollToTop />
      </>
    </div>
  );
}

export default App;
