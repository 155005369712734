import React, { FunctionComponent, useContext, useMemo } from "react";
import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { useNavigate } from "react-router";
import { ZodError, ZodSchema, z } from "zod";

// helpers
import {
  ApiError,
  ValidationError,
  isExtendedError,
  unknownToError,
} from "../error-provider/error";

// consts
import { PATHS } from "../../route/route.controls";

// types
import type { ErrorResponse } from "../error-provider/ErrorProvider.types";

// context
import { sessionContext } from "../session-provider/SessionProvider";
import { errorContext } from "../error-provider/ErrorProvider";

type ApiContext = {
  api: <T>(
    url: string,
    config?: AxiosRequestConfig | null,
    schema?: ZodSchema<T>,
    type?: string
  ) => Promise<T | null>;
};

type ApiProviderProps = {
  children: JSX.Element;
};

export const apiContext = React.createContext({} as ApiContext);

export const ApiProvider: FunctionComponent<ApiProviderProps> = (props) => {
  const { error } = useContext(errorContext);
  const { getSessionCookie, getRefreshSessionCookie, removeSessionCookie } =
    useContext(sessionContext);

  const navigate = useNavigate();

  const { children } = props;

  const urlApi = process.env.REACT_APP_STAGE_API_URL;

  async function api<T>(
    url: string,
    config: AxiosRequestConfig | null = { method: "GET" },
    schema: ZodSchema<T> = z.any(),
    type?: string
  ): Promise<T | null> {
    const verifyToken = getSessionCookie();
    const refreshVerifyToken = getRefreshSessionCookie();

    const currentUrl = url.includes("http") ? url : `${urlApi}${url}`;

    const currentConfig = config ? config : {};
    try {
      const response = await axios(currentUrl, {
        headers: {
          "Content-Type": type ? type : "application/json",
          ...(verifyToken !== null && {
            authorization: `Bearer ${verifyToken}`,
          }),
        },
        ...currentConfig,
      });

      if (response.status === 204) return null;

      if (response.status === 401 && refreshVerifyToken) {
        const urlForRefreshToken = `${urlApi}/auth/refresh`;
        const response = await axios(urlForRefreshToken, {
          headers: {
            "Content-Type": "application/json",
            ...(refreshVerifyToken !== null && {
              authorization: refreshVerifyToken,
            }),
          },
          ...currentConfig,
        });
        return response.data;
      }

      if (response.status !== 200 && response.status !== 201) {
        throw new ValidationError(`${response.status} ${response.data}`, {
          code: response.status,
          data: response.data,
        });
      }

      const isCurrent = schema.parse(response.data);

      if (isCurrent) return isCurrent;

      return null;
    } catch (e) {
      if (isExtendedError(e)) {
        if (e instanceof AxiosError) {
          const customError = e.response?.data as ErrorResponse;
          if (
            e.response?.status === 401 &&
            customError.message === "Unauthorized"
          ) {
            removeSessionCookie();
            error("Please first log in or register.");
            navigate(PATHS.index);

            return null;
          }
          throw e;
        }
        throw e;
      }
      const err = unknownToError(e);

      if (e instanceof ZodError) {
        console.error("Zod Error", e.issues);

        throw new ValidationError(e.name, {
          data: e.message,
        });
      }

      throw new ApiError(err, {
        code: 500,
        data: {
          url: currentUrl,
        },
      });
    }
  }

  const contextValue = useMemo(() => ({ api }), [api]);

  return (
    <apiContext.Provider value={contextValue}>{children}</apiContext.Provider>
  );
};
