import React from "react";
import { useNavigate } from "react-router-dom";

// icons
import { FailedIcon } from "../../../assets/icons/FailedIcon";

// consts
import { PATHS } from "../../../route/route.controls";

// components
import Button from "../../../components/button/Button";

// styles
import styles from "./PaymentFailed.module.scss";

export function PaymentFailed() {
  const navigate = useNavigate();

  return (
    <section className={styles.section}>
      <div className={styles.mainContent}>
        <FailedIcon />
        <p className={styles.title}>Payment was declined</p>
        <p className={styles.description}>
          Please try again later or contact our support service
        </p>
      </div>

      <div className={styles.buttonsWrapper}>
        <Button onClick={() => navigate(PATHS.profile)}>My profile</Button>
      </div>
    </section>
  );
}
