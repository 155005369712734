import React, { useCallback } from "react";

// icons
import { CloseIcon } from "../../assets/icons/CloseIcon";

// components
import Button from "../button/Button";

// styles
import styles from "./TeacherNotification.module.scss";

type TeacherNotificationProps = {
  onClick: () => void;
  onClose: () => void;
};

export function TeacherNotification({
  onClick,
  onClose,
}: TeacherNotificationProps) {
  const handleClick = useCallback(() => {
    onClick();
    onClose();
  }, [onClose, onClick]);

  return (
    <section className={styles.notificationSection}>
      <div className={styles.container}>
        <div className={styles.top}>
          <div className={styles.circles}>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div onClick={onClose} className={styles.closeIcon}>
            <CloseIcon width={22} height={22} />
          </div>
        </div>
        <div className={styles.wrapper}>
          <div className={styles.body}>
            <p>
              <b>Sign up for a group</b> with a specialised coach in:
              management, business and soft skills
            </p>
            <Button className={styles.Button} onClick={handleClick}>
              Learn More
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
}
