import React, { FunctionComponent, useMemo, useState } from "react";

// consts
import { CART } from "./CartProvider.consts";

// types
import type {
  CartContext,
  CartProduct,
  CartProviderProps,
} from "./CartProvider.types";
import type { CollectionType } from "../collections-provider/CollectionsProvider.types";

export const cartContext = React.createContext({} as CartContext);

export const CartProvider: FunctionComponent<CartProviderProps> = (props) => {
  const { children } = props;

  const [cartProducts, setCartProducts] = useState<CartProduct[]>([]);
  const [cartCount, setCartCount] = useState(0);
  const [totalPrice, setTotalPrice] = useState("");

  const getCartProducts = () => {
    const cartString = localStorage.getItem(CART);

    const cart: CartProduct[] = cartString ? JSON.parse(cartString) : [];

    if (cart.length) {
      const total = cart
        .map((cartProduct) => {
          if (
            "discountPrice" in cartProduct &&
            "quantity" in cartProduct &&
            cartProduct.discountPrice
          ) {
            return Number(cartProduct.discountPrice) * cartProduct.quantity;
          }
          if ("quantity" in cartProduct) {
            return Number(cartProduct.price) * cartProduct.quantity;
          }
          return Number(cartProduct.price);
        })
        .reduce((acc, currValue) => acc + currValue)
        .toFixed(2);
      setTotalPrice(total);
    }

    setCartProducts(cart);
    setCartCount(cart.length);
  };

  const addBookToCart = (book: CartProduct) => {
    const cartString = localStorage.getItem(CART);

    const cart: CartProduct[] = cartString ? JSON.parse(cartString) : [];

    const updatedCart: CartProduct[] = cart.map((cartBook) => {
      if ("quantity" in cartBook && cartBook.id === book.id) {
        return { ...cartBook, quantity: cartBook.quantity + 1 };
      }

      return cartBook;
    });

    if (
      !updatedCart.some((cartBook) => {
        return cartBook.id === book.id;
      })
    ) {
      const addedCart = [...updatedCart, book];

      localStorage.setItem(CART, JSON.stringify(addedCart));

      return setCartCount(addedCart.length);
    }

    const currentCart = updatedCart.length ? updatedCart : [{ ...book }];

    localStorage.setItem(CART, JSON.stringify(currentCart));

    return setCartCount(currentCart.length);
  };

  const addCollectionToCart = (collection: CartProduct) => {
    const cartString = localStorage.getItem(CART);

    const cart: CartProduct[] = cartString ? JSON.parse(cartString) : [];

    if (
      !cart.some((cartCollection) => {
        return cartCollection.id === collection.id;
      })
    ) {
      const addedCart = [...cart, collection];

      localStorage.setItem(CART, JSON.stringify(addedCart));

      return setCartCount(addedCart.length);
    }

    const currentCart = cart.length ? cart : [{ ...collection }];

    localStorage.setItem(CART, JSON.stringify(currentCart));

    return setCartCount(currentCart.length);
  };

  const addQuantity = (book: CartProduct) => {
    const cartString = localStorage.getItem(CART);

    const cart: CartProduct[] = cartString ? JSON.parse(cartString) : [];

    const updatedCart: CartProduct[] = cart.map((cartBook) => {
      if ("quantity" in cartBook && cartBook.id === book.id) {
        return { ...cartBook, quantity: cartBook.quantity + 1 };
      }

      return cartBook;
    });

    localStorage.setItem(CART, JSON.stringify(updatedCart));
  };

  const removeQuantity = (book: CartProduct) => {
    const cartString = localStorage.getItem(CART);

    const cart: CartProduct[] = cartString ? JSON.parse(cartString) : [];

    const updatedCart: CartProduct[] = cart.map((cartBook) => {
      if ("quantity" in cartBook && cartBook.id === book.id) {
        return { ...cartBook, quantity: cartBook.quantity - 1 };
      }

      return cartBook;
    });

    const filteredCart = updatedCart.filter((product) => {
      if ("quantity" in product) {
        return product.quantity > 0;
      }
      return product;
    });

    localStorage.setItem(CART, JSON.stringify(filteredCart));
    return setCartCount(filteredCart.length);
  };

  const deleteBookFromCart = (bookId: string) => {
    const cartString = localStorage.getItem(CART);

    const cart: CartProduct[] = cartString ? JSON.parse(cartString) : [];

    const updatedCart: CartProduct[] = cart.filter(
      (cartBook) => cartBook.id !== bookId
    );

    localStorage.setItem(CART, JSON.stringify(updatedCart));
    return setCartCount(updatedCart.length);
  };

  const deleteCollectionFromCart = (collectionId: string) => {
    const cartString = localStorage.getItem(CART);

    const cart: CartProduct[] = cartString ? JSON.parse(cartString) : [];

    const updatedCart: CartProduct[] = cart.filter(
      (cartCollection) => cartCollection.id !== collectionId
    );

    localStorage.setItem(CART, JSON.stringify(updatedCart));
    return setCartCount(updatedCart.length);
  };

  const contextValue = useMemo(
    () => ({
      cartProducts,
      cartCount,
      totalPrice,
      getCartProducts,
      addBookToCart,
      addCollectionToCart,
      addQuantity,
      removeQuantity,
      deleteBookFromCart,
      deleteCollectionFromCart,
    }),
    [
      cartProducts,
      cartCount,
      totalPrice,
      getCartProducts,
      addBookToCart,
      addCollectionToCart,
      addQuantity,
      removeQuantity,
      deleteBookFromCart,
      deleteCollectionFromCart,
    ]
  );

  return (
    <cartContext.Provider value={contextValue}>{children}</cartContext.Provider>
  );
};
