import React, { useContext } from "react";
import { Outlet } from "react-router-dom";

// components
import { Header } from "../../components/header/Header";
import { Aside } from "../../components/aside/Aside";
import { Footer } from "../../components/footer/Footer";

// context
import { sessionContext } from "../../context/session-provider/SessionProvider";

// styles
import styles from "./UserLayout.module.scss";

export function UserLayout(): JSX.Element {
  const { getRoleFromToken } = useContext(sessionContext);

  const role = getRoleFromToken();

  return (
    <div className={styles.container}>
      <Header isAdminRoutes />
      <div className={styles.mainWrapper}>
        <Aside role={role} />

        <div className={styles.outletWrapper}>
          <Outlet />
        </div>
      </div>
      <Footer />
    </div>
  );
}
