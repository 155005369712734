import React from "react";

type ArrowLeftProps = {
  className?: string;
  color?: string;
  width?: number;
  height?: number;
};

const ArrowRightIcon = ({
  className,
  color = "#F1F1F1",
  width = 12,
  height = 11,
}: ArrowLeftProps) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 12 11"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.48942 0.46051C7.10666 0.0623765 6.47362 0.0499177 6.07549 0.432682C5.67735 0.815446 5.66489 1.44849 6.04766 1.84662L8.26555 4.15357H1C0.447715 4.15357 0 4.60128 0 5.15357C0 5.70585 0.447715 6.15357 1 6.15357H8.26555L6.04766 8.46051C5.66489 8.85864 5.67735 9.49168 6.07549 9.87445C6.47362 10.2572 7.10666 10.2448 7.48942 9.84662L11.335 5.84662L12.0013 5.15357L11.335 4.46051L7.48942 0.46051Z"
        fill={color}
        fillOpacity={color === "#fff" ? 1 : 0.6}
      />
    </svg>
  );
};
export { ArrowRightIcon };
