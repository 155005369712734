import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

// icons
import { SuccessIcon } from "../../../assets/icons/SuccessIcon";

// consts
import { PATHS } from "../../../route/route.controls";
import { CART } from "../../../context/cart-provider/CartProvider.consts";

// components
import Button from "../../../components/button/Button";

// styles
import styles from "./PaymentSuccess.module.scss";

//translation
import { Trans } from "@lingui/react";

export function PaymentSuccess() {
  const navigate = useNavigate();

  useEffect(() => {
    typeof localStorage !== "undefined" && localStorage.removeItem(CART);
  }, []);

  return (
    <section className={styles.section}>
      <div className={styles.mainContent}>
        <SuccessIcon />
        <p className={styles.title}>
          <Trans id="Payment successfully completed" />
        </p>
        <p className={styles.description}>
          <Trans id="You can find your purchase in your profile." />
        </p>
      </div>

      <div className={styles.buttonsWrapper}>
        <Button onClick={() => navigate(PATHS.profile)}>
          <Trans id="My profile" />
        </Button>

        <Button
          className={styles.mainButton}
          onClick={() => navigate(PATHS.index)}>
          <Trans id="To main page" />
        </Button>
      </div>
    </section>
  );
}
