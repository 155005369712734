import React, { useCallback, useContext } from "react";
import classnames from "classnames";

// components
import Modal from "../../../../components/modal/Modal";
import Input from "../../../../components/input/Input";
import Button from "../../../../components/button/Button";
import Textarea from "../../../../components/textarea/Textarea";
import Checkbox from "../../../../components/checkbox/Checkbox";
import { Select } from "../../../../components/select/Select";

// icons
import { DefaultImageIcon } from "../../../../assets/icons/DefaultImageIcon";

// hooks
import { useWindowSize } from "../../../../hooks/use-window-size/use-window-size";
import { useBookAdminForm } from "../use-books-admin";

// context
import { errorContext } from "../../../../context/error-provider/ErrorProvider";
import { storageContext } from "../../../../context/storage-provider/StorageProvider";

// types
import type { BookType } from "../../../../context/books-provider/BooksProvider.types";

// styles
import styles from "./BookAdminModal.module.scss";

type BookAdminModalProps = {
  book: BookType | null;
  isOpen: "create" | "edit" | null;
  onClose: () => void;
};

export function BookAdminModal({ book, isOpen, onClose }: BookAdminModalProps) {
  const { error } = useContext(errorContext);
  const { uploadProgress } = useContext(storageContext);

  const {
    bookAdminFormData,
    pictureInputRef,
    bookInputRef,
    isDisabledSubmitButton,
    categoryBooksAdminOptions,
    tagsBooksAdminOptions,
    handleChangeBookAdminData,
    handleChangeSelectData,
    handleRemovePicture,
    handleUpload,
    handleRemoveSource,
    getRootProps,
    getInputProps,
    isDragAccept,
    isDragReject,
    onSubmit,
  } = useBookAdminForm(isOpen, book);

  const { width } = useWindowSize();

  const isCreatingModal = isOpen === "create";

  const modalHeader = isCreatingModal ? "Create new book" : "Edit book";

  const isUploadingProcess = () => {
    if (bookAdminFormData.source) {
      return uploadProgress > 0 && uploadProgress < 100 ? uploadProgress : 100;
    }

    return uploadProgress;
  };

  const uploadingFileTitle = () => {
    if (bookAdminFormData.source) {
      return uploadProgress > 0 && uploadProgress < 100
        ? `${uploadProgress}%`
        : bookAdminFormData.source.fileName;
    }

    return `${uploadProgress}%`;
  };

  const handleSubmit = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      try {
        await onSubmit(e);
        onClose();
      } catch (e) {
        error(e);
      }
    },
    [onSubmit, onClose, error]
  );

  return (
    <Modal
      isOpen={Boolean(isOpen)}
      onClose={onClose}
      header={modalHeader}
      width={width > 767 ? "65%" : "97%"}
    >
      <form className={styles.form}>
        <div className={styles.fileWrapper}>
          <div className={styles.pictureFileWrapper}>
            Choose pictures
            <div className={styles.pictures}>
              {bookAdminFormData.pictures.length
                ? bookAdminFormData.pictures.map((picture) => (
                    <div className={styles.pictureWrapper} key={picture}>
                      <img
                        src={`data:image/png;base64, ${picture}`}
                        alt="book"
                      />
                      <div
                        className={styles.deleteUploadingPicture}
                        onClick={() => handleRemovePicture(picture)}
                      >
                        <p>Delete</p>
                      </div>
                    </div>
                  ))
                : null}
              <div {...getRootProps()}>
                <input
                  type="file"
                  accept="image/png, image/jpeg"
                  style={{ display: "none" }}
                  {...getInputProps()}
                />
                <div
                  className={classnames(
                    styles.defaultPicture,
                    isDragAccept ? styles.acceptedPicture : "",
                    isDragReject ? styles.rejectedPicture : ""
                  )}
                  onClick={(e) => {
                    e.preventDefault();
                    pictureInputRef.current?.click();
                  }}
                >
                  <DefaultImageIcon
                    color={
                      isDragAccept
                        ? "#83d17e"
                        : isDragReject
                        ? "#ef4343"
                        : undefined
                    }
                  />
                  <p>
                    {isDragAccept
                      ? "File is OK. You can drop here"
                      : isDragReject
                      ? "File is NOT OK"
                      : "Drag 'n' drop your image here, or click to select"}
                  </p>
                </div>
              </div>
            </div>
            <p>JPG, PNG up to 480 kb</p>
          </div>
          <div className={styles.productFileWrapper}>
            Download book
            <input
              ref={bookInputRef}
              type="file"
              accept=".pdf"
              onChange={handleUpload}
              style={{ display: "none" }}
            />
            <Button
              width="auto"
              variant="avatar"
              onClick={(e) => {
                e.preventDefault();
                bookInputRef.current.click();
              }}
            >
              Choose file
            </Button>
            <div className={styles.uploadProgressBarWrapper}>
              <div
                className={styles.uploadProgressBar}
                style={{
                  width: `${isUploadingProcess()}%`,
                }}
              ></div>
              <div className={styles.uploadedTitle}>
                <p>{uploadingFileTitle()}</p>
              </div>

              {bookAdminFormData.source ? (
                <div
                  className={styles.deleteUploadingFile}
                  onClick={handleRemoveSource}
                >
                  Delete
                </div>
              ) : null}
            </div>
          </div>
        </div>

        <div className={styles.inputWrapper}>
          <Input
            label="Title"
            name="title"
            value={bookAdminFormData.title}
            onChange={handleChangeBookAdminData}
          />
          <Input
            label="Author"
            name="author"
            value={bookAdminFormData.author}
            onChange={handleChangeBookAdminData}
          />
        </div>

        <div className={styles.inputWrapper}>
          <Textarea
            id="description"
            label="Description"
            name="description"
            variant="admin"
            value={bookAdminFormData.description}
            onChange={handleChangeBookAdminData}
          />
        </div>

        <div className={styles.inputWrapper}>
          <Input
            label="Price"
            name="price"
            value={bookAdminFormData.price}
            onChange={handleChangeBookAdminData}
          />
          <Input
            label="Discount Price"
            name="discountPrice"
            value={bookAdminFormData.discountPrice || ""}
            onChange={handleChangeBookAdminData}
          />
        </div>

        <div className={styles.inputWrapper}>
          <div className={styles.checkboxWrapper}>
            <Checkbox
              id="Is published"
              name="isPublished"
              value="1"
              checked={bookAdminFormData.isPublished}
              onChange={handleChangeBookAdminData}
            />
            <p className={styles.checkboxText}>Is Published</p>
          </div>

          <Input
            label="Pages"
            name="pages"
            type="number"
            value={bookAdminFormData.pages}
            onChange={handleChangeBookAdminData}
          />
        </div>

        <div className={styles.inputWrapper}>
          <div className={styles.selectWrapper}>
            <Select
              name="category"
              placeholder="Category"
              options={categoryBooksAdminOptions}
              value={categoryBooksAdminOptions.find(
                ({ value }) => value === bookAdminFormData.category
              )}
              onChange={handleChangeSelectData}
            />
          </div>

          <div className={styles.selectWrapper}>
            <Select
              name="tags"
              placeholder="Tags"
              options={tagsBooksAdminOptions}
              value={tagsBooksAdminOptions.filter((option) =>
                bookAdminFormData.tags.includes(option.value)
              )}
              isMulti
              onChange={handleChangeSelectData}
            />
          </div>
        </div>

        <div className={styles.buttonsWrapper}>
          <Button
            onClick={onClose}
            variant="outline"
            className={styles.cancelButton}
          >
            Cancel
          </Button>

          <Button
            onClick={handleSubmit}
            type="submit"
            disabled={isDisabledSubmitButton}
          >
            Submit
          </Button>
        </div>
      </form>
    </Modal>
  );
}
