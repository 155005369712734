import { useCallback, useContext, useState } from "react";
import { Link } from "react-router-dom";

// hooks
import { useCartModalOrder } from "./use-cart-modal-order";

// components
import Button from "../../button/Button";
import Checkbox from "../../checkbox/Checkbox";
import Input from "../../input/Input";

// consts
import { PATHS } from "../../../route/route.controls";

// context
import { errorContext } from "../../../context/error-provider/ErrorProvider";
import { orderContext } from "../../../context/order-provider/OrderProvider";
import { paymentContext } from "../../../context/payement-provider/PaymentProvider";
import { localesContext } from "../../../context/local-provider/LocalProvider";
import { currencyContext } from "../../../context/currency-provider/CurrencyProvider";

// types
import type { BookType } from "../../../context/books-provider/BooksProvider.types";
import type { CollectionType } from "../../../context/collections-provider/CollectionsProvider.types";

// styles
import styles from "./CartModalOrder.module.scss";

//translations
import { t } from "@lingui/macro";
import { Trans } from "@lingui/react";

type CartModalOrderProps = {
  items: number;
  filteredCart: {
    books: BookType[];
    collections: CollectionType[];
  };
  total: string;
  onCloseModal: () => void;
};

export function CartModalOrder({
  items,
  filteredCart,
  total,
  onCloseModal,
}: CartModalOrderProps) {
  const { i18n } = useContext(localesContext);
  const { error } = useContext(errorContext);
  const { createOrder } = useContext(orderContext);
  const { checkoutPayment } = useContext(paymentContext);
  const { getCurrencySymbol } = useContext(currencyContext);

  const {
    couponCode,
    couponDiscountPrice,
    isDisabledSubmitButton,
    handleChangeCouponAdminData,
    onSubmit,
  } = useCartModalOrder();

  const [isConfirmedTerms, setIsConfirmedTerms] = useState(false);

  const handleCheckout = useCallback(async () => {
    try {
      const booksIds = filteredCart.books.map((book) => book.id);

      const collectionsIds = filteredCart.collections.map(
        (collection) => collection.id,
      );

      const orderData = await createOrder(
        booksIds,
        collectionsIds,
        couponCode ? couponCode : undefined,
      );

      if (orderData) {
        await checkoutPayment(orderData.id);
      }
    } catch (e) {
      error(e);
    }
  }, [checkoutPayment, total, filteredCart, couponCode]);

  return (
    <div className={styles.orderWrapper}>
      <div>
        <h6>
          <Trans id="Order Summary" />
        </h6>
      </div>
      <div className={styles.infoWrapper}>
        <div className={styles.info}>
          <p>
            <Trans id="Subtotal" /> ({items} <Trans id="items" />
            ):
          </p>
          <p>
            {getCurrencySymbol()}
            {total}
          </p>
        </div>

        <div className={styles.couponWrapper}>
          <Input
            placeholder={t(i18n)`Enter coupon`}
            value={couponCode}
            onChange={handleChangeCouponAdminData}
          />{" "}
          <Button
            className={styles.couponButton}
            onClick={onSubmit}
            disabled={isDisabledSubmitButton}>
            <Trans id="Check coupon" />
          </Button>
        </div>

        <div className={styles.checkboxWrapper}>
          <Checkbox
            id="terms_and_conditions"
            name="terms"
            value="1"
            checked={isConfirmedTerms}
            onChange={() => setIsConfirmedTerms((prev) => !prev)}
          />

          <p className={styles.checkboxText}>
            <Trans id="I have read and agree with" />{" "}
            <Link to={PATHS.termsAndConditions} onClick={onCloseModal}>
              <Trans id="Terms of service" />
            </Link>{" "}
            <Trans id="and" />{" "}
            <Link to={PATHS.privacyPolicy} onClick={onCloseModal}>
              <Trans id="Privacy policy" />
            </Link>
          </p>
        </div>

        <div className={styles.total}>
          <p>
            <Trans id="Total" />
          </p>
          {couponDiscountPrice ? (
            <p>
              {getCurrencySymbol()}
              {total} - {couponDiscountPrice}% = {getCurrencySymbol()}
              {(
                Number(total) -
                Number(total) * (couponDiscountPrice / 100)
              ).toFixed(2)}
            </p>
          ) : (
            <p>
              {getCurrencySymbol()}
              {total}
            </p>
          )}
        </div>
        <div className={styles.separator} />
        <div>
          <Button onClick={handleCheckout} disabled={!isConfirmedTerms}>
            <Trans id="Secure Checkout" />
          </Button>
        </div>
      </div>
    </div>
  );
}
