import React, { useCallback } from "react";
import { generatePath, useNavigate } from "react-router-dom";

// components
import Button from "../../../../../components/button/Button";

// hooks
import { getBase64ImgSrc } from "../../../../../helpers/getBase64ImageSrc";

// images
import defaultBookCover from "../../../../../assets/images/default_book_cover.png";

// types
import { BookType } from "../../../../../context/books-provider/BooksProvider.types";

// consts
import { PATHS } from "../../../../../route/route.controls";

// styles
import styles from "./Book.module.scss";

type BookProps = {
  book: BookType;
};

export function Book({ book }: BookProps) {
  const navigate = useNavigate();

  const handleOpenCurrentBook = useCallback(
    (bookId: string) => {
      const generatedLink = generatePath(PATHS.book, {
        bookId,
      });

      navigate(generatedLink);
    },
    [navigate, PATHS]
  );

  return (
    <div
      className={styles.bookContainer}
      onClick={() => handleOpenCurrentBook(book.id)}
    >
      <div className={styles.imageWrapper}>
        <img
          src={
            book.pictures[0]
              ? getBase64ImgSrc(book.pictures[0])
              : defaultBookCover
          }
          alt="course"
        />
      </div>
      <div className={styles.info}>
        <p className={styles.description}>{book.title}</p>
        <p className={styles.author}>by {book.author}</p>
      </div>
      <div className={styles.buttonsWrapper}>
        <Button width="auto">Buy</Button>
        <div>
          <div className={styles.pricesWrapper}>
            {book.discountPrice ? <p>€{book.discountPrice}</p> : null}
            <div className={book.discountPrice ? styles.diagonalLine : ""}>
              <p className={styles.oldPrice}>€{book.price}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
