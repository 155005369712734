import React, { useContext, useEffect, useState } from "react";

// images
import defaultBookCover from "../../../assets/images/default_book_cover.png";

// hooks
import { useBookFetch } from "./use-book";

// context
import { booksContext } from "../../../context/books-provider/BooksProvider";

// components
import Spinner from "../../../components/spinner/Spinner";
import { AddToCart } from "./add-to-cart/AddToCart";

// helpers
import { getBase64ImgSrc } from "../../../helpers/getBase64ImageSrc";

// styles
import styles from "./BookScreen.module.scss";

export function BookScreen() {
  const { bookById } = useContext(booksContext);
  const { isBookLoading } = useBookFetch();

  const [selectedImage, setSelectedImage] = useState("");

  const bookTitle = (
    genre:
      | "business"
      | "management"
      | "self-realization"
      | "motivation"
      | "Time management"
      | ""
  ) => {
    switch (genre) {
      case "business":
        return "Business";

      case "management":
        return "Management";

      case "self-realization":
        return "Self realization";

      case "motivation":
        return "Motivation";

      case "Time management":
        return "Time managementt";
      default:
        return "";
    }
  };

  useEffect(() => {
    if (bookById?.pictures.length) {
      return setSelectedImage(getBase64ImgSrc(bookById.pictures[0]));
    }
    setSelectedImage(defaultBookCover);
  }, [bookById]);

  return (
    <section className={styles.container}>
      {isBookLoading ? (
        <div className={styles.spinner}>
          <Spinner color="dark" />
        </div>
      ) : bookById ? (
        <div className={styles.circleWrapper}>
          {/* Backgrodund Circles */}
          <div className={styles.violetCircle} />

          <div className={styles.redCircle} />

          <div className={styles.redDownCircle} />

          <div className={styles.content}>
            <h3>{bookById.title}</h3>
            <div className={styles.bookWrapper}>
              <div className={styles.bookMain}>
                <div className={styles.bookInfoWrapper}>
                  <div className={styles.imagesWrapper}>
                    <div className={styles.selectedImage}>
                      <img src={selectedImage} alt={bookById.title} />
                    </div>
                    <div className={styles.imagesListWrapper}>
                      {bookById.pictures.length ? (
                        bookById.pictures.map((picture, index) => (
                          <div key={picture} className={styles.pictureWrapper}>
                            <img
                              src={getBase64ImgSrc(picture)}
                              alt={`${index} book`}
                              onClick={() =>
                                setSelectedImage(getBase64ImgSrc(picture))
                              }
                            />
                            {selectedImage === getBase64ImgSrc(picture) ? (
                              <div className={styles.pictureDivider} />
                            ) : null}
                          </div>
                        ))
                      ) : (
                        <div>
                          <img
                            src={defaultBookCover}
                            alt={`default cover`}
                            onClick={() => setSelectedImage(defaultBookCover)}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={styles.bookTypeWrapper}>
                    <div className={styles.bookType}>
                      <p className={styles.bookTypeText}>Type</p>
                      <p>{bookTitle(bookById.category)}</p>
                    </div>

                    {bookById.tags.length ? (
                      <div className={styles.bookType}>
                        <p className={styles.bookTypeText}>Tags</p>
                        <p>{bookById.tags.join(", ")}</p>
                      </div>
                    ) : null}

                    {bookById.source ? (
                      <div className={styles.bookType}>
                        <p className={styles.bookTypeText}>Format included</p>
                        <p>✔ {bookById.source.extension}</p>
                      </div>
                    ) : null}

                    <div className={styles.bookType}>
                      <p className={styles.bookTypeText}>Pages</p>
                      <p>{bookById.pages} pages</p>
                    </div>
                  </div>
                </div>

                <div className={styles.divider} />

                <div className={styles.description}>
                  <h6>Description</h6>
                  <p>{bookById.description}</p>
                </div>
              </div>

              <AddToCart book={bookById} />
            </div>
          </div>
        </div>
      ) : null}
    </section>
  );
}
