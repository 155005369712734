// images
import introduction_books from "../../../../assets/images/introduction_books.png";
import books_src from "../../../../assets/images/books.png";
import hygge_book_src from "../../../../assets/images/hygge_book.png";

// icons
import { LogoWithUnderline } from "../../../../assets/icons/LogoWithUnderline";

export const introductionItemsData = [
  {
    title: (
      <>
        <h2>Fuel your</h2>
        <h2>motivation with</h2>
        <LogoWithUnderline />
      </>
    ),
    description:
      "Fuel your motivation with our wide selection of books on cultivating self-esteem and setting goals for achieving success.",
    img: <img src={introduction_books} alt="books on hand" />,
  },
  {
    title: (
      <>
        <h2>Discover worlds </h2>
        <h2>within pages </h2>
        <h2>
          at <LogoWithUnderline />
        </h2>
      </>
    ),
    description:
      "Dive into captivating stories, enrich your mind, and fuel your imagination with our curated collection. Experience the joy of discovery as you explore boundless realms through the power of books.",
    img: <img src={books_src} alt="hygge book" />,
  },
  {
    title: (
      <>
        <h2>Discover top</h2>
        <h2>rated books of</h2>
        <h2>last month</h2>
      </>
    ),
    description:
      "Uncover the literary gems that captured readers' hearts with our selection of top-rated books from last month.Explore the titles that garnered rave reviews and discover your next must-read masterpiece.",
    img: <img src={hygge_book_src} alt="books on hand" />,
  },
];
