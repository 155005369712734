import React, { useContext } from "react";

// components
import { RatedBooks } from "../../guest/main-screen/rated-books/RatedBooks";
import { Pagination } from "../../../components/pagination/Pagination";
import Spinner from "../../../components/spinner/Spinner";

// context
import { collectionsContext } from "../../../context/collections-provider/CollectionsProvider";

// hooks
import { useCollectionsFetch } from "./use-collections";

// styles
import styles from "./CollectionsScreen.module.scss";

export function CollectionsScreen() {
  const { total, collectionsData } = useContext(collectionsContext);

  const { isCollectionsLoading, setItemOffset } = useCollectionsFetch();

  return (
    <div className={styles.container}>
      <div className={styles.collectionsWrapper}>
        <div className={styles.orangeCircle} />

        <div className={styles.redCircle} />

        <div className={styles.redDownCircle} />

        <div className={styles.yellowCircle} />
        {isCollectionsLoading ? (
          <div className={styles.spinner}>
            <Spinner color="dark" />
          </div>
        ) : (
          <>
            {collectionsData && collectionsData.length ? (
              <>
                <RatedBooks
                  title="Best collections"
                  type="collections"
                  collections={collectionsData}
                />
                <Pagination
                  amountOfItems={30}
                  length={total}
                  setItemOffset={setItemOffset}
                />
              </>
            ) : (
              <h4 className={styles.title}>
                Regrettably, there are currently no books on collections.
              </h4>
            )}
          </>
        )}
      </div>
    </div>
  );
}
