import React, { useCallback, useContext, useState } from "react";

// context
import { booksContext } from "../../../context/books-provider/BooksProvider";
import { errorContext } from "../../../context/error-provider/ErrorProvider";

// components
import Spinner from "../../../components/spinner/Spinner";
import { ItemsCounter } from "../../../components/items-counter/ItemsCounter";
import { BookAdminModal } from "./BookAdminModal/BookAdminModal";
import { ConfirmModal } from "../../../components/confirm-modal/ConfirmModal";
import { Pagination } from "../../../components/pagination/Pagination";

// icons
import { DeleteIcon } from "../../../assets/icons/DeleteIcon";
import { EditIcon } from "../../../assets/icons/EditIcon";

// images
import defaultBookCover from "../../../assets/images/default_book_cover.png";

// hooks
import { useBookAdminForm, useBooksAdminFetch } from "./use-books-admin";

// helpers
import { getBase64ImgSrc } from "../../../helpers/getBase64ImageSrc";

// type
import type { BookType } from "../../../context/books-provider/BooksProvider.types";

// styles
import styles from "./BooksAdminScreen.module.scss";

export function BooksAdminScreen() {
  const { error } = useContext(errorContext);
  const { books, total } = useContext(booksContext);

  const { isBooksAdminLoading, itemsAmount, setItemsAmount, setItemOffset } =
    useBooksAdminFetch();
  const { handleRemoveBook } = useBookAdminForm();

  const [isOpenBookModal, setIsOpenBookModal] = useState<
    "create" | "edit" | null
  >(null);
  const [isOpenRemovingBookModal, setIsOpenRemovingBookModal] = useState(false);
  const [selectedBook, setSelectedBook] = useState<BookType | null>(null);

  const handleOpenEditingModal = (book: BookType) => {
    setIsOpenBookModal("edit");
    setSelectedBook(book);
  };

  const handleOpenDeletingModal = (book: BookType) => {
    setIsOpenRemovingBookModal(true);
    setSelectedBook(book);
  };

  const onDeleteBook = useCallback(
    async (bookId?: string) => {
      try {
        await handleRemoveBook(bookId);
      } catch (e) {
        error(e);
      } finally {
        setIsOpenRemovingBookModal(false);
      }
    },
    [handleRemoveBook, setIsOpenRemovingBookModal, error]
  );

  if (isBooksAdminLoading) {
    return (
      <div className={styles.spinner}>
        <Spinner color="white" />
      </div>
    );
  }

  return (
    <section className={styles.container}>
      <ItemsCounter itemsAmount={itemsAmount} setItemsAmount={setItemsAmount} />

      {books && books.length ? (
        <div className={styles.tableWrapper}>
          <div className={styles.tableRowHead}>
            <div>Photo</div>
            <div>Title</div>
            <div>Description</div>
            <div>Type</div>
            <div>Tags</div>
            <div>Price</div>
            <div>Discount Price</div>
            <div>Status</div>
            <div></div> {/*For edit button */}
            <div></div> {/*For delete button */}
          </div>

          {books.map((book) => (
            <div className={styles.tableRowBody} key={book.id}>
              <div className={styles.imageWrapper}>
                <img
                  src={
                    book.pictures[0]
                      ? getBase64ImgSrc(book.pictures[0])
                      : defaultBookCover
                  }
                  alt={book.title}
                />
              </div>
              <div>{book.title}</div>
              <div>{book.description}</div>
              <div>{book.category}</div>
              <div>{book.tags ? book.tags.join(", ") : null}</div>
              <div>{book.price}</div>
              <div>{book.discountPrice}</div>
              <div>{book.isPublished ? "Yes" : "No"}</div>
              <div className={styles.icon}>
                <EditIcon
                  color="white"
                  onClick={() => handleOpenEditingModal(book)}
                />
              </div>
              <div className={styles.icon}>
                <DeleteIcon onClick={() => handleOpenDeletingModal(book)} />
              </div>
            </div>
          ))}

          <Pagination
            variant="admin"
            amountOfItems={itemsAmount}
            length={total}
            setItemOffset={setItemOffset}
          />
        </div>
      ) : (
        <h4>Unfortunately, information about the books is not available.</h4>
      )}

      <BookAdminModal
        isOpen={isOpenBookModal}
        onClose={() => setIsOpenBookModal(null)}
        book={selectedBook}
      />

      <ConfirmModal
        isOpen={isOpenRemovingBookModal}
        modalTitle="Delete product"
        modalText="Are you sure you want to delete this product?"
        onClose={() => setIsOpenRemovingBookModal(false)}
        onSubmit={() => onDeleteBook(selectedBook?.id)}
      />
    </section>
  );
}
