import React, { useMemo } from "react";

// component
import SelectReact from "react-select";

// types
import type { StylesConfig } from "react-select/dist/declarations/src/styles";
import type { StateManagerProps } from "react-select/dist/declarations/src/useStateManager";

/**
 * react-select styled for different themes.
 * used for synchronous operations, there is a built-in search by options.
 *
 * @param restParam (all props that are available for react select)
 * @returns JSX.Element} component CustomReactSelect.
 */
export function CurrencySelector({ ...restParam }: StateManagerProps) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const customStyles: StylesConfig<any> = useMemo(
    () => ({
      input: (provided) => ({
        ...provided,
        width: "fit-content",
        color: "#000000",
        margin: 0,
        padding: 0,
      }),

      valueContainer: (provided) => ({
        ...provided,
        padding: 0,
        margin: 0,
      }),

      control: (provided, state) => ({
        ...provided,
        background: "transparent",
        border: "none",
        fontFamily: "Inter, sans-serif",
        fontSize: "16px",
        lineHeight: "20px",
        cursor: "pointer",
        width: "60px",
        boxShadow: state.isFocused ? "none" : provided.boxShadow,
      }),

      menu: (provided) => ({
        ...provided,
        zIndex: 10,
        backgroundColor: "#fff",
        borderRadius: "8px",
        overflow: "hidden",
        boxShadow: "0 0 0 1px #00000033",
      }),

      menuList: (provided) => ({
        ...provided,
        background: "#fff",
        padding: 0,
      }),

      option: (provided, { isDisabled, isFocused }) => ({
        ...provided,
        padding: "4px 14px",
        cursor: isDisabled ? "not-allowed" : "default",
        textTransform: isDisabled ? "uppercase" : undefined,
        backgroundColor: isFocused ? "#00000033" : "#fff",
        color: "#111726",
      }),

      singleValue: (provided) => ({
        ...provided,
        color: "#111726",
        fontSize: "16px",
        fontWeight: "500",
        lineHeight: "20px",
        margin: 0,
      }),

      multiValue: (provided) => ({
        ...provided,
        backgroundColor: "transparent",
        color: "#000000",
        margin: 0,
      }),

      multiValueLabel: (provided) => ({
        ...provided,
        color: "#000000",
        fontSize: "14px",
        fontWeight: "500",
        lineHeight: "20px",
        padding: 0,
      }),

      placeholder: (provided) => ({
        ...provided,
        color: "#9b9ea6",
      }),

      indicatorsContainer: (provided) => ({
        ...provided,
        padding: 0,
        marginLeft: "4px",
      }),

      dropdownIndicator: (provided) => ({
        ...provided,
        padding: 0,
        color: "#111726",
      }),

      // To reset default styles
      indicatorSeparator: () => ({}),
    }),
    []
  );

  return <SelectReact styles={customStyles} {...restParam} />;
}
