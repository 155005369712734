import React, { FunctionComponent, useContext, useMemo } from "react";

// contexts
import { apiContext } from "../api-provider/ApiProvider";
import { currencyContext } from "../currency-provider/CurrencyProvider";

// consts
import {
  API_URL_PAYMENT_CHECKOUT,
  API_URL_PAYMENT_CHECKOUT_CONFIRM,
} from "./PaymentProvider.consts";

// schemas
import { paymentSchema, refillSchema } from "./PaymentProvider.schemas";

// types
import type {
  PaymentContext,
  PaymentFormType,
  PaymentProviderProps,
} from "./PaymentProvider.types";

export const paymentContext = React.createContext({} as PaymentContext);

export const PaymentProvider: FunctionComponent<PaymentProviderProps> = (
  props
) => {
  const { api } = useContext(apiContext);
  const { getCurrency } = useContext(currencyContext);

  const { children } = props;

  const checkoutPayment = async (orderId: string) => {
    try {
      const currentCurrency = getCurrency();

      const payment = await api(
        API_URL_PAYMENT_CHECKOUT,
        { method: "POST", data: { orderId, currency: currentCurrency } },
        refillSchema
      );

      if (payment) {
        window.location.href = payment.url;
      }
    } catch (error) {
      throw error;
    }
  };

  const checkoutPaymentConfirm = async (formData: PaymentFormType) => {
    try {
      await api(
        API_URL_PAYMENT_CHECKOUT_CONFIRM,
        { method: "POST", data: formData },
        paymentSchema
      );
    } catch (error) {
      throw error;
    }
  };

  const contextValue = useMemo(
    () => ({
      checkoutPayment,
      checkoutPaymentConfirm,
    }),
    [checkoutPayment, checkoutPaymentConfirm]
  );

  return (
    <paymentContext.Provider value={contextValue}>
      {children}
    </paymentContext.Provider>
  );
};
