import { useCallback, useContext, useMemo, useState } from "react";

// context
import { errorContext } from "../../../../context/error-provider/ErrorProvider";
import { userContext } from "../../../../context/user-provider/UserProvider";

// consts
import { INIT_FEEDBACK_FORM_DATA } from "./Feedback.consts";

// types
import type { FeedbackFormType } from "../../../../context/user-provider/UserProvider.types";

export function useFeedbackModalForm() {
  const { error, success } = useContext(errorContext);
  const { feedback } = useContext(userContext);

  const [feedbackFormData, setFeedbackFormData] = useState<FeedbackFormType>(
    INIT_FEEDBACK_FORM_DATA
  );

  const handleChangeFeedbackData = useCallback(
    (
      e:
        | React.ChangeEvent<HTMLInputElement>
        | React.ChangeEvent<HTMLTextAreaElement>
    ) => {
      const { name, value } = e.target;

      setFeedbackFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    },
    [setFeedbackFormData]
  );

  const onSubmit = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      try {
        await feedback(feedbackFormData);

        success("Your feedback has been successfully submitted.");
        setFeedbackFormData(INIT_FEEDBACK_FORM_DATA);
      } catch (e) {
        error(e);
      }
    },
    [feedbackFormData, feedback, success, setFeedbackFormData, error]
  );

  const isDisabledSubmitButton = useMemo(
    () =>
      !feedbackFormData.firstName ||
      !feedbackFormData.lastName ||
      !feedbackFormData.email ||
      !feedbackFormData.phone ||
      !feedbackFormData.message,
    [feedbackFormData]
  );

  return {
    feedbackFormData,
    isDisabledSubmitButton,
    handleChangeFeedbackData,
    onSubmit,
  };
}
