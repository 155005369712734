import React from "react";

// styles
import styles from "./CircleWrapper.module.scss";

type CircleWrapperProps = {
  children: React.ReactNode;
};

// XXX: Be careful, this component is especially for the main screen.

export function CircleWrapper({ children }: CircleWrapperProps) {
  return (
    <div className={styles.circleWrapper}>
      {/* Up Circles */}

      <div className={styles.whiteCircle} />

      <div className={styles.violetCircle} />

      <div className={styles.redCircle} />

      <div className={styles.orangeCircle} />

      <div className={styles.yellowCircle} />

      <div className={styles.bigRedCircle} />

      {/* Down Circles */}

      <div className={styles.violetDownCircle} />

      <div className={styles.whiteDownCircle} />

      <div className={styles.redDownCircle} />

      <div className={styles.orangeDownCircle} />

      <div className={styles.yellowDownCircle} />

      <div className={styles.bigRedDownCircle} />

      {children}
    </div>
  );
}
