//consts
import { PATHS } from "../../route/route.controls";

export const ADMIN_TABS = [
  {
    title: "Books",
    to: PATHS.adminBooks,
  },
  {
    title: "Collections",
    to: PATHS.adminCollections,
  },
  {
    title: "Coupons",
    to: PATHS.adminCoupons,
  },
];
