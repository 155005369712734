import React from "react";
import { useNavigate } from "react-router-dom";

// components
import Modal from "../modal/Modal";
import { CartModalBook } from "./cart-modal-book/CartModalBook";
import { CartModalOrder } from "./cart-modal-order/CartModalOrder";
import Button from "../button/Button";
import { CartModalCollection } from "./cart-modal-collection/CartModalCollection";

// consts
import { PATHS } from "../../route/route.controls";

// types
import type { CartProduct } from "../../context/cart-provider/CartProvider.types";

// styles
import styles from "./CartModal.module.scss";

type CartModalProps = {
  isOpen: boolean;
  cartProducts: CartProduct[];
  total: string;
  onClose: () => void;
};

export function CartModal({
  isOpen,
  cartProducts,
  total,
  onClose,
}: CartModalProps) {
  const navigate = useNavigate();

  const amountOfItems = cartProducts.length
    ? cartProducts
        .map((product) => {
          if ("quantity" in product) {
            return product.quantity;
          }
          return 1;
        })
        .reduce((acc, curVal) => acc + curVal)
    : 0;

  const idsWithQuantity = cartProducts.flatMap((product) => {
    if ("quantity" in product) {
      return Array.from({ length: product.quantity }, () => product.id);
    }
    return [];
  });

  const collectionsId = cartProducts
    .map((product) => {
      if ("books" in product) {
        return product.id;
      }
      return "";
    })
    .filter((id) => id !== "");

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      header={
        <div>
          <p>My order</p>
          <p className={styles.description}>
            Review and edit your order, then proceed to Checkout.
          </p>
        </div>
      }
      className={styles.modal}
      width="85%"
    >
      <form className={styles.form}>
        <div>
          {cartProducts.length ? (
            <div className={styles.cartWrapper}>
              <div className={styles.booksWrapper}>
                {cartProducts.map((product) => {
                  if ("quantity" in product) {
                    return (
                      <CartModalBook cartProduct={product} key={product.id} />
                    );
                  }
                  return (
                    <CartModalCollection
                      cartProduct={product}
                      key={product.id}
                    />
                  );
                })}
              </div>

              <CartModalOrder
                total={total}
                items={amountOfItems}
                idsWithQuantity={idsWithQuantity}
                collectionsId={collectionsId}
                onCloseModal={onClose}
              />
            </div>
          ) : (
            <div className={styles.emptyCart}>
              <h3>Your shopping cart is empty</h3>
              <p>But you can choose something for yourself</p>
              <div>
                <Button
                  onClick={() => navigate(`${PATHS.books}/genre/business`)}
                  width="auto"
                >
                  Go shopping
                </Button>
              </div>
            </div>
          )}
        </div>
      </form>
    </Modal>
  );
}
