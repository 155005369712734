import React from "react";

// images
import present_src from "../../../../assets/images/present.png";

// styles
import styles from "./Discount.module.scss";

export function Discount() {
  return (
    <section className={styles.discountSection}>
      <div className={styles.contentWrapper}>
        <div className={styles.discountInfo}>
          <p>Come in and Join us</p>
          <h1>
            Get <span>20% Off</span>
          </h1>
          <p>for all products</p>
        </div>
        <div className={styles.discountImage}>
          <img src={present_src} alt="present" />
        </div>
      </div>
    </section>
  );
}
