import React from "react";
import classnames from "classnames";

// styles
import styles from "./Textarea.module.scss";

type Props = {
  autoFocus?: boolean;
  id: string;
  name: string;
  label?: string;
  width?: string;
  placeholder?: string;
  variant?: "default" | "admin";
  required?: boolean;
  className?: string;
  showValidation?: boolean;
  rows?: number;
  value?: string;
  disabled?: boolean;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  validityMessage?: string;
  onBlur?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
};

export default function Textarea(props: Props): JSX.Element {
  const {
    label = "",
    required = false,
    value,
    variant = "default",
    showValidation = false,
    placeholder = "",
    width = "100%",
    id,
    className,
    autoFocus = false,
    ...attrs
  } = props;

  const isEnabledShowValidation = showValidation && value;

  return (
    <div
      className={classnames(
        styles["textarea-input-label"],
        styles.inputWrapper
      )}
      style={{ width }}
    >
      {label && (
        <label
          className={classnames(styles.label, {
            [styles.withoutValue]: !value,
            [styles.withValue]: value,
          })}
        >
          <p className={styles.text}>{label}</p>
        </label>
      )}
      <textarea
        {...(autoFocus ? { autoFocus } : {})}
        className={classnames(
          className,
          styles["textarea-input"],
          {
            [styles["show-validation"]]: isEnabledShowValidation,
          },
          styles[variant]
        )}
        rows={4}
        required={required}
        placeholder={`${placeholder}${
          placeholder && required && !label ? "*" : ""
        }`}
        value={value}
        id={id}
        {...attrs}
      />
    </div>
  );
}
