import React from "react";

// images
import present_src from "../../../../assets/images/present.png";

// styles
import styles from "./Discount.module.scss";

//translations
import { Trans } from "@lingui/react";

export function Discount() {
  return (
    <section className={styles.discountSection}>
      <div className={styles.contentWrapper}>
        <div className={styles.discountInfo}>
          <p>
            <Trans id="Come in and Join us" />
          </p>
          <h1>
            <Trans id="Get" />{" "}
            <span>
              <Trans id="20% Off" />
            </span>
          </h1>
          <p>
            <Trans id="for all products" />
          </p>
        </div>
        <div className={styles.discountImage}>
          <img src={present_src} alt="present" />
        </div>
      </div>
    </section>
  );
}
