// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ResetPasswordScreen_section__m6NPj {
  display: grid;
  grid-template-columns: 0.5fr;
  align-items: center;
  justify-content: center;
  margin: 200px 0;
}

.ResetPasswordScreen_form__2JDLs {
  display: grid;
  gap: 24px;
}

.ResetPasswordScreen_buttonWrapper__UMYeh {
  justify-self: center;
}`, "",{"version":3,"sources":["webpack://./src/side/general/reset-password-screen/ResetPasswordScreen.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,4BAAA;EACA,mBAAA;EACA,uBAAA;EAEA,eAAA;AAAF;;AAGA;EACE,aAAA;EACA,SAAA;AAAF;;AAGA;EACE,oBAAA;AAAF","sourcesContent":[".section {\n  display: grid;\n  grid-template-columns: 0.5fr;\n  align-items: center;\n  justify-content: center;\n\n  margin: 200px 0;\n}\n\n.form {\n  display: grid;\n  gap: 24px;\n}\n\n.buttonWrapper {\n  justify-self: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": `ResetPasswordScreen_section__m6NPj`,
	"form": `ResetPasswordScreen_form__2JDLs`,
	"buttonWrapper": `ResetPasswordScreen_buttonWrapper__UMYeh`
};
export default ___CSS_LOADER_EXPORT___;
